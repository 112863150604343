import React, { createContext, useState } from 'react';
import { Vehicle, VehicleSearch, VehicleBrand } from '../models';

const SearchContext = createContext({} as any)

const SearchProvider = (props: any) => {
  const [vehicleContext, setVehicleContext] = useState<Vehicle | null>(null);
  const [vehiclesCompareContext, setVehiclesCompareContext] = useState<Vehicle[]>([]);
  const [vehiclesSearchedContext, setVehiclesSearchedContext] = useState<VehicleSearch[]>([]);
  const [vehicleBrandContext, setVehicleBrandContext] = useState<VehicleBrand | null>(null);
  
  return (
    <SearchContext.Provider
      value={{
        vehicleContext, setVehicleContext,
        vehiclesCompareContext, setVehiclesCompareContext,
        vehiclesSearchedContext, setVehiclesSearchedContext,
        vehicleBrandContext, setVehicleBrandContext
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };