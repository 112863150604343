import { Combo } from "c4u-web-components";
import { useKbb, useSessionContext } from "..";
import { LocalStorageKeys } from "../../constants";
import { VehicleBrandModels, VehicleSearch } from "../../models";

export const useStateStorage = () => {
  const { GetAllLocationState } = useKbb();
  const { anonymousContext } = useSessionContext();

  const setStateStorage = (item: string): void => {
    localStorage.setItem(LocalStorageKeys.SELECTED_STATE, item);
  };

  const getStateStorage = async (): Promise<number> => {
    const item = localStorage.getItem(LocalStorageKeys.SELECTED_STATE);
    if (item && !anonymousContext?.isAnonymous) return Number(item);

    const statesService = await GetAllLocationState();
    const defaultState = statesService.find((f) => f.isDefault);
    const defaultStateAnonymous = statesService.find(
      (f) => f.twoLetterCode.toLowerCase() === "sc"
    );
    const defaultState4Rodas = statesService.find(
      (f) => f.twoLetterCode.toLowerCase() === "sp"
    );

    if (anonymousContext?.isAnonymous && defaultStateAnonymous) {
      if (
        anonymousContext.plugin !== null &&
        anonymousContext.plugin !== undefined &&
        defaultState4Rodas
      ) {
        setStateStorage(defaultState4Rodas.id.toString());
        return defaultState4Rodas.id;
      } else {
        setStateStorage(defaultStateAnonymous.id.toString());
        return defaultStateAnonymous.id;
      }
    }

    if (!anonymousContext?.isAnonymous && defaultState) {
      setStateStorage(defaultState.id.toString());
      return defaultState.id;
    }

    return 25;
  };

  const setAllHistoryStorage = (vehicles: VehicleSearch[]): void => {
    localStorage.removeItem(LocalStorageKeys.LASTEST_SEARCHES);
    localStorage.setItem(
      LocalStorageKeys.LASTEST_SEARCHES,
      JSON.stringify(vehicles)
    );
  };

  const setAllStatesStorage = (combo: Combo[]) => {
    localStorage.removeItem(LocalStorageKeys.ALL_STATES);
    localStorage.setItem(LocalStorageKeys.ALL_STATES, JSON.stringify(combo));
  };

  const setAllBrandStorage = (brands: VehicleBrandModels[]) => {
    localStorage.removeItem(LocalStorageKeys.ALL_VEHICLE_MODELS);
    localStorage.setItem(
      LocalStorageKeys.ALL_VEHICLE_MODELS,
      JSON.stringify(brands)
    );
  };

  const getLocalStorage = (key: string) => {
    const inStorage = localStorage.getItem(key);

    if (inStorage) {
      return JSON.parse(inStorage);
    } else {
      return [];
    }
  };

  return {
    setStateStorage,
    getStateStorage,
    setAllHistoryStorage,
    setAllStatesStorage,
    getLocalStorage,
    setAllBrandStorage,
  };
};
