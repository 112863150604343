import styled from 'styled-components';
import Card from 'react-bootstrap/esm/Card';
import Row from 'react-bootstrap/esm/Row';

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 40px auto 0 auto;
  .row:first-child .mb-2 .card:first-child {
    width: auto;
    margin: auto;
  }
  
  .CardWrapper{
    border: none;
  }
`;

const CardStyled = styled(Card)`
height: 208px;
/*max-width: 176px;*/
.card-body {
  padding: 15px;
}
`;

const CardStyledInfo = styled(CardStyled)`
  background-color: ${props => props.theme.colors.BlueNavy};
  border-radius: 4px;
  border: none;
`;

const CardInfoHeader = styled.div`
  margin: -23px 0 0 0;
  text-align: center;
`;

const CardInfoTitle = styled.div`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  text-align: center;
`;
const CardInfoDescription = styled.div`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 10px;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
  text-align: center;
  line-height: 13px;
  margin: 5px 0 0 0;
`;

const DivCategoryTitle = styled.div`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.Dark};
  line-height: 19px;
    margin: 0 0 6px 0;
`;

const RowPrice = styled(Row)`
  margin: 43px -15px 49px -15px;
`;

export {
  Wrapper, CardStyled, CardStyledInfo, CardInfoHeader, CardInfoTitle, CardInfoDescription, RowPrice, DivCategoryTitle
}