import React, { useState, useMemo, useEffect } from 'react';
import { EmptyResult, CardLineAccordion, RowLineAccordion, LineAccordionTitleText, LineAccordionInfo, LineAccordionTitle, ColContentInfo } from './compare-vehicles-info.style';
import Col from 'react-bootstrap/esm/Col';

interface CompareVehiclesInfoProps {
    title: string;
    values: string[] | JSX.Element[];
    unic?: number | string;
}

export const CompareVehiclesInfo = (props: CompareVehiclesInfoProps) => {
    const [title, setTitle] = useState(props.title);
    const [values, setValues] = useState(props.values);

    const items = useMemo(() => {
        return ['-', '-', '-']
    }, []);

    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);

    useEffect(() => {
        setValues(props.values);
    }, [props.values]);

    const formatText = (text: string) =>{
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    }

    return (
        <CardLineAccordion key={`CardLineAccordion-${props.unic}`}>
            <RowLineAccordion key={`RowLineAccordion-${props.unic}`}>
                <Col key={`Col-${props.unic}`} sm>
                    <LineAccordionTitle key={`LineAccordionTitle-${props.unic}`}>
                        <LineAccordionTitleText key={`LineAccordionTitleText-${props.unic}`}>{formatText(title)}</LineAccordionTitleText>
                    </LineAccordionTitle>
                </Col>
                {items.map((v, i) =>
                    !!values[i] ?
                        (
                            <ColContentInfo key={`ColContentInfo-${i}-${props.unic}`} sm>
                                <LineAccordionInfo key={`LineAccordionInfo-${i}-${props.unic}`}>{values[i]}</LineAccordionInfo>
                            </ColContentInfo>
                        )
                        :
                        (
                            <ColContentInfo sm key={`ColContentInfo-${i}-${props.unic}`}> <EmptyResult key={`EmptyResult-${i}-${props.unic}`}> {v}</EmptyResult></ColContentInfo>
                        )
                )}
            </RowLineAccordion >
        </CardLineAccordion >
    );
}