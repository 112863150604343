import {
  BaseResponseKbb,
  LocationState,
  PriceAdvisorRequest,
  PriceAdvisorResponse,
  Vehicle,
  VehicleBrand,
  VehicleBrandModels,
  VehicleEquipment,
  VehicleModel,
  VehiclePrices,
  VehicleVersion,
  Years,
} from "../../../models";
import { kbbHttp, useRequest } from "../config";

export const useKbb = () => {
  const { get, post } = useRequest(kbbHttp, "KbbMolicar");

  const GetAllLocationState = async (): Promise<LocationState[]> => {
    const { data } = await get("GetAllLocationState");
    return data;
  };

  const GetAllVehicleBrand = async (): Promise<VehicleBrand[]> => {
    const { data } = await get("GetAllVehicleBrand");
    return data;
  };

  const GetAllVehicleModelByBrand = async (
    brandId: number,
    year: number | null = null
  ): Promise<VehicleModel[]> => {
    const { data } = await get(
      `GetAllVehicleModelByBrand/${brandId}` + (year ? `?year=${year}` : "")
    );
    return data;
  };

  const GetAllVehicleModels = async (): Promise<VehicleBrandModels[]> => {
    const { data } = await get(`GetAllVehicleModels`);
    return data;
  };

  const GetAllVehicleVersion = async (
    modelId: number,
    year: number
  ): Promise<VehicleVersion[]> => {
    const { data } = await get(`GetAllVehicleVersion/${year}/${modelId}`);
    return data;
  };

  const GetAllYears = async (): Promise<Years[]> => {
    const { data } = await get(`GetAllYears`);
    return data;
  };

  const GetVehicle = async (id: number): Promise<Vehicle> => {
    const { data } = await get(`GetVehicle/${id}`);
    return data;
  };

  const GetAllVehicleGrade = async (): Promise<BaseResponseKbb[]> => {
    try {
      const { data } = await get(`GetAllVehicleGrade`);
      return data;
    } catch (error) {
      return [];
    }
  };

  const GetPriceAdvisor = async (
    request: PriceAdvisorRequest
  ): Promise<PriceAdvisorResponse> => {
    const { data } = await post(`GetPriceAdvisor`, request);
    return data;
  };

  const FormatGetInfoVehicle = (
    request: PriceAdvisorRequest,
    equips: number[]
  ): string => {
    let param = "?";
    Object.keys(request).forEach((prop) => {
      param = param + `${prop}=${(request as any)[prop]}&`;
    });
    return `GetInfoVehicle${param}${
      equips?.length > 0
        ? equips.map((m) => `idsEquipments=${m}`).join("&")
        : "&idsEquipments=0"
    }`;
  };
  const GetInfoVehicle = async (
    request: PriceAdvisorRequest,
    equips: number[]
  ): Promise<PriceAdvisorResponse> => {
    const param = FormatGetInfoVehicle(request, equips);
    const { data } = await get(`${param}`);
    return data;
  };

  const GetAllEquipments = async (
    id: number | null = null,
    year: number | null = null
  ): Promise<VehicleEquipment[]> => {
    const { data } = await get(`GetAllEquipments${id ? `/${id}/${year}` : ""}`);
    return data;
  };

  const GetVehiclePrices = async (
    request: PriceAdvisorRequest
  ): Promise<VehiclePrices> => {
    try {
      const { data } = await post(`GetVehiclePrices`, request);
      return data;
    } catch (error) {
      return {} as VehiclePrices;
    }
  };

  return {
    FormatGetInfoVehicle,
    GetInfoVehicle,
    GetAllLocationState,
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetAllVehicleBrand,
    GetVehicle,
    GetAllVehicleModels,
    GetAllVehicleGrade,
    GetPriceAdvisor,
    GetAllEquipments,
    GetVehiclePrices,
  };
};
