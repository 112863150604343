import { HttpHelper } from "c4u-web-components";
import { GetAnonymousRequest, GetAnonymousResponse } from "../../../models";
import { kbbHttp, useRequest } from "../config";

export const useKbbAnonymous = () => {
  const { get } = useRequest(kbbHttp, "Anonymous");

  const getUrlAnonymous = async (
    request: GetAnonymousRequest
  ): Promise<GetAnonymousResponse> => {
    const { data } = await get(
      `GetUrlAnonymous${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  return {
    getUrlAnonymous,
  };
};
