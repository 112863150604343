import styled from 'styled-components';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { darken } from 'polished';

const Wrapper = styled.div`
    margin: 32px 0 0 0;
    .rbt-menu {
        width: 100%;
    }
    .dropdown {
        z-index: 1;
        width: 30%;
        text-align: left;
        padding: 0;
        .buttonDropdown {
            color: ${props => props.theme.colors.COXDarkBlue};
            margin: 0 0 0 15px;
        }
        label {
            width: 70%;
            margin: 0 0 0 2px;
            cursor: pointer;
        }
        
    }
`;

const DivDropdown = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.Platinum};
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    .show:first-child {
            display: block;
        }
`;

const InputGroupStyled = styled(InputGroup)`
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.Platinum};
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;

    input {
        width: 100%;
        border: 0;
    }
    
`;

const AutoDropdown = styled.div`
    flex: 1;
`;

const Separator = styled.div`
    width: 1px;
    height: 24px;
    background: ${({ theme }) => theme.colors.Platinum};
    margin: 0 5px;
`;

const ButtonStyled = styled(Button)`
    background: ${({ theme }) => theme.colors.ButtonPrimary.Main};
    border-radius: 0px 4px 4px 0px;
    width: 72px;
    :hover {
        background-color: ${props => darken(0.1, props.theme.colors.ButtonPrimary.Main)};
    }
`;

export {
    InputGroupStyled as InputGroup,
    Separator,
    ButtonStyled as Button,
    Wrapper,
    DivDropdown,
    AutoDropdown,
}
