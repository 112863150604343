import React, { useState } from "react";
import { Wrapper, RowStyled, Col } from "./search-vehicle.organism.style";
import Row from "react-bootstrap/Row";
import { BoxIconSearch, BarFieldSearch } from "../../../molecules";
import { SearchTypeConstant } from "../../../../constants";
import { useTranslation } from "react-i18next";
import { Tooltip } from "c4u-web-components";

export const SearchVehicle = () => {
  const { t } = useTranslation();

  const [activeCar, setActiveCar] = useState(true);
  const [activeMotorcycle, setActiveMotorcycle] = useState(false);
  const [activeTruck, setActiveTruck] = useState(false);
  const [activeBus, setActiveBus] = useState(false);
  const [activeImplement, setActiveImplement] = useState(false);

  const click = (id: string): void => {
    setActiveCar(id === SearchTypeConstant.CARSMALL);
    setActiveMotorcycle(id === SearchTypeConstant.MOTORCYCLE);
    setActiveTruck(id === SearchTypeConstant.TRUCK);
    setActiveBus(id === SearchTypeConstant.BUS);
    setActiveImplement(id === SearchTypeConstant.IMPLEMENT);
  };

  return (
    <Wrapper>
      <Row>
        <Col md={2}></Col>
        <Col md>
          <RowStyled>
            <Col lg={1}></Col>
            <Col sm>
              <BoxIconSearch
                label={t("Cars")}
                icon={SearchTypeConstant.CARSMALL}
                active={activeCar}
                click={() => click(SearchTypeConstant.CARSMALL)}
              />
            </Col>
            <Col sm>
              <Tooltip content={t("Soon")}>
                <BoxIconSearch
                  label={t("Motorcycles")}
                  icon={SearchTypeConstant.MOTORCYCLE}
                  active={activeMotorcycle}
                />
              </Tooltip>
            </Col>
            <Col sm>
              <Tooltip content={t("Soon")}>
                <BoxIconSearch
                  label={t("Trucks")}
                  icon={SearchTypeConstant.TRUCK}
                  active={activeTruck}
                />
              </Tooltip>
            </Col>
            <Col sm>
              <Tooltip content={t("Soon")}>
                <BoxIconSearch
                  label={t("Bus")}
                  icon={SearchTypeConstant.BUS}
                  active={activeBus}
                />
              </Tooltip>
            </Col>
            <Col sm>
              <Tooltip content={t("Soon")}>
                <BoxIconSearch
                  label={t("Implements")}
                  icon={SearchTypeConstant.IMPLEMENT}
                  active={activeImplement}
                />
              </Tooltip>
            </Col>
            <Col lg={1}></Col>
          </RowStyled>
          <Row>
            <Col>
              <BarFieldSearch />
            </Col>
          </Row>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Wrapper>
  );
};
