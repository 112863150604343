//import withTranslation from "react-i18next";

export class NumberHelper {

    static _DECIMAL = ',';
    static _MILHAR = '.';

    static toFormatString(number: string | number, precision = 0, zeroFormat = "0"): string {
        const numberTyped = typeof (number) === 'string' ? Number(number) : number;
        const numberDecimal = numberTyped.toFixed(precision).replace('.', this._DECIMAL);
        const result = numberDecimal.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${this._MILHAR}`);
        return numberTyped === 0 ? zeroFormat : result;
    }
}