import { theme } from "c4u-web-components";
import React, { Suspense } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import TagManager from "react-gtm-module";
import { ThemeProvider } from "styled-components";
import "./App.css";
import { PriceProvider, SearchProvider, SessionProvider } from "./contexts";
import { Routes } from "./router";
import GlobalStyles from "./ui/styles";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        <SessionProvider>
          <SearchProvider>
            <PriceProvider>
              <Routes />
            </PriceProvider>
          </SearchProvider>
        </SessionProvider>
      </Suspense>
      <GlobalStyles />
    </ThemeProvider>
  );
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTMID ?? "dummy",
  dataLayerName: "registration",
  dataLayer: {
    page: "init",
  },
};
if (process.env.REACT_APP_GTMID) TagManager.initialize(tagManagerArgs);

export default App;
