/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.GhostWhite};
  }

  .container {
    position: relative;
    left: 32px;
  }

  @media (max-width: 1080px){
    .container {
      padding-left: 24px;
    }
  }

  @media (max-width: 576px){
    .container {
      left: 0px;
      padding-left: 12px;
    }
  }

  body, input, button {
    font: 1rem 'Open Sans', sans-serif;
  }

  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  .show{
    display: initial;
  }
  .hide{
    display: none;
  }
  .linkKbbPriceAdvisor {
    position: absolute;
    width: 80%;
    margin: auto;
    left: 0;
    right: 0;
    top: 30%;
    bottom: 27%;
  }
`;
