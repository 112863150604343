import styled from 'styled-components';
import Row from 'react-bootstrap/esm/Row';

interface Props {
  disabled: boolean;
}
const CardNewVehicle = styled.div<Props>`
  cursor: ${props => props.disabled ? 'inicial' : 'pointer'};
  opacity: ${props => props.disabled ? 0.35 : 1};
`;
const RowCombo = styled(Row)`
  padding-bottom: 5px;
`;
const CardCarHeader = styled.div`
  margin: -13px -11px 2px 0;
  text-align: right;
  cursor: pointer;
`;
const CardCarTitle = styled.div`
  font-family: ${props => props.theme.fontFamily.Montserrat};
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.Dark};
  margin: 0 0 6px 0;
`;

const CardCarSubTitle = styled.div`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  color: ${props => props.theme.colors.Dark};
  line-height: 16px;
  margin: 0 0 6px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CardCarDescription = styled.div`
  height: 103px;
  img {
    max-height: 130px;
    width: 100%;
  }
`;

const OptionalsEditBox = styled.div`
  height: 26px;
  width: 100%;
  background-color: ${props => props.theme.colors.BlueNavy};
  color: ${props => props.theme.colors.white};
  font-size: 12px;
  font-weight: bold;

  position: absolute;
  margin: 3px -15px;
  padding: 3px;
  border-radius: 0 0 4px 4px;
  text-align: center;
  bottom: -3px;

  cursor: pointer;
`;

const CardEmptyIcon = styled.div<Props>`
  background-color: ${props => props.disabled ? props.theme.colors.DarkGrayOpacity : props.theme.colors.ButtonPrimary.Main};
  color: ${props => props.theme.colors.ButtonPrimary.Secondary};
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
  font-size: 36px;
  font-family: ${props => props.theme.fontFamily.OpenSans};
  margin: 45px auto 0 auto;
`;

const CardEmptyText = styled.div`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  color: ${props => props.theme.colors.Dark};
  text-align: center;
  line-height: 20px;
  margin: 6px 0 0 0;
`;

const WrapperCombos = styled.div`
  background-color: ${props => props.theme.colors.BlueNavy};
  position: absolute;
  width: 100%;
  padding: 15px 23px;
  margin: -15px;
  border-radius: 4px;
  height: 100%;

  .dropdown {
    height: 30px;
    padding: 4px 9px;
    font-size: 12px;
  }

  .dropdown-item{
    white-space: normal;
  }
`;

const CloseButton = styled.div`
text-align: right;
color: ${props => props.theme.colors.white};
margin: -12px -14px 4px 0;
font-size: 12px;
span {
  cursor: pointer;
}
`;

const DivLoading = styled.div`
display: flex;
align-items: center;
text-align: center;
align-content: center;
height: 100%;
`;
const DivTextLoading = styled.div`
width: 100%;
`;

export {
    CardCarHeader, CardCarTitle, CardCarSubTitle, CardCarDescription, CardEmptyIcon, CardEmptyText, CardNewVehicle, WrapperCombos, RowCombo, CloseButton, DivLoading, DivTextLoading, OptionalsEditBox
}