import { useAuth0 } from "@auth0/auth0-react";
import {
  ButtonPrimary,
  ButtonSecondary,
  H2,
  H3,
  ModalDefault,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { CompareVehicles } from "../..";
import { VehicleCompareProvider } from "../../../../contexts";
import {
  useKbb,
  usePriceContext,
  useSearchContext,
  useSessionContext,
} from "../../../../hooks";
import { Vehicle, VehicleSearch } from "../../../../models";
import {
  EquipmentsOptions,
  PriceAdvisorView,
  VehicleFeatures,
  VehicleSample,
} from "../../../molecules";
import {
  Button,
  Description,
  DivBoxVehicleFeature,
  RowFooter,
  Wrapper,
} from "./price-advisor.organism.style";

export const PriceAdvisor = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();

  const [vehicleOriginal, setVehicleOriginal] = useState<Vehicle>();
  const [idSearch] = useState<number>(Date.now());

  const { addRouterParamContext, anonymousContext } = useSessionContext();
  const {
    vehicleContext,
    setVehicleContext,
    vehiclesCompareContext,
    setVehiclesCompareContext,
    setVehiclesSearchedContext,
    vehiclesSearchedContext,
  } = useSearchContext();
  const {
    setOpenModalEquipamentsOptionsContext,
    openModalEquipamentsOptionsContext,
    openModalCompareContext,
    setOpenModalCompareContext,
    setEditEquipmentsCompareContext,
    setContinueProcess,
    continueProcess,
    equipmentsColor,
    setEquipmentsColor,
    equipmentsIds,
    setEquipmentsIds,
    setKmsVC,
    setEquipmentsColorVC,
    setEquipmentsPriceVC,
    setEquipmentsIdsVC,
  } = usePriceContext();

  const [equipmentsColorLocal, setEquipmentsColorLocal] =
    useState(equipmentsColor);
  const [equipmentsIdsLocal, setEquipmentsIdsLocal] = useState(equipmentsIds);

  const params = useParams();

  const { GetVehicle } = useKbb();

  const vehicleIdParam = useMemo(() => {
    return (params as any).vehicleId;
  }, [params]);

  const vehicleSearchIdParam: number = useMemo<number>(() => {
    return (params as any).vehicleSearchId | 0;
  }, [params]);

  const onHideModalCompare = () => {
    setOpenModalCompareContext(false);
    setVehiclesCompareContext([]);
    setKmsVC([]);
    setEquipmentsColorVC([]);
    setEquipmentsPriceVC([]);
    setEquipmentsIdsVC([]);
    setEditEquipmentsCompareContext(false);
  };

  const handleCancelClickModalEquipments = () => {
    setOpenModalEquipamentsOptionsContext(false);
    setEditEquipmentsCompareContext(false);

    setEquipmentsColor(equipmentsColorLocal);
    setEquipmentsIds(equipmentsIdsLocal);
  };

  const getVehicleAsync = useCallback(
    async (vehicleId: number): Promise<void> => {
      if (vehicleId && vehicleId !== vehicleContext?.id) {
        try {
          setVehicleContext(null);
          const items = await GetVehicle(vehicleId);
          setVehicleContext(items);
        } catch (e) {
          console.log("erro no price advisor", e);
        }
      }
    },
    [GetVehicle, setVehicleContext, vehicleContext]
  );

  useEffect(() => {
    getVehicleAsync(vehicleIdParam);
  }, [vehicleIdParam]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehiclesCompareContext?.length > 0) setOpenModalCompareContext(true);
  }, [vehiclesCompareContext, setOpenModalCompareContext]);

  useEffect(() => {
    if (!openModalCompareContext && vehicleOriginal)
      setVehicleContext(vehicleOriginal);
    else if (openModalCompareContext) setVehicleOriginal(vehicleContext);
  }, [
    openModalCompareContext,
    setVehicleContext,
    vehicleOriginal,
    vehicleContext,
  ]);

  useEffect(() => {
    if (vehicleContext) {
      if (vehicleContext.brandID)
        addRouterParamContext(":brandId", vehicleContext.brandID.toString());

      const vehiclesContext = vehiclesSearchedContext.filter(
        (f) => f.id !== idSearch
      );

      const vehicle = {} as VehicleSearch;
      vehicle.id = idSearch;
      vehicle.vehicle = vehicleContext;

      const vehiclesSearched = [...vehiclesContext, vehicle];
      setVehiclesSearchedContext(vehiclesSearched);
    }
  }, [vehicleContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (continueProcess) setEditEquipmentsCompareContext(false);
  }, [continueProcess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (openModalEquipamentsOptionsContext) {
      setEquipmentsColorLocal(equipmentsColor);
      setEquipmentsIdsLocal(equipmentsIds);
    }
  }, [openModalEquipamentsOptionsContext]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFooter = (
    <RowFooter>
      <Col>
        <Button variant="link" onClick={handleCancelClickModalEquipments}>
          {t("Cancel")}
        </Button>
      </Col>
      <Col className="text-right">
        <ButtonPrimary sizex={"md"} onClick={() => setContinueProcess(true)}>
          {t("Continue")}
        </ButtonPrimary>
      </Col>
    </RowFooter>
  );

  return (
    <>
      <Row>
        <Col xs={"auto"}>
          <H2>{t("PricePage")}</H2>
          <H3>{t("Select the category of price below")}.</H3>
          <PriceAdvisorView
            vehicle={vehicleContext}
            vehicleSearchId={vehicleSearchIdParam}
            isAuthenticated={isAuthenticated}
          />
        </Col>

        <Col xs={"auto"}>
          <DivBoxVehicleFeature>
            <VehicleFeatures reduced={true} />
            <div className={"my-3 text-right"}>
              <Link
                to={`/search/0${anonymousContext?.anonymousUrlParam ?? ""}`}
              >
                <ButtonSecondary sizex={"md"}>
                  {t("New Search")}
                </ButtonSecondary>
              </Link>
            </div>
          </DivBoxVehicleFeature>
        </Col>
      </Row>

      <VehicleCompareProvider>
        <ModalDefault
          show={openModalEquipamentsOptionsContext}
          title={t("Vehicle Options")}
          onHide={handleCancelClickModalEquipments}
          footer={renderFooter}
          dialogClassName="width-850"
        >
          <Wrapper>
            <Description>{t("OptionalVehicleModalDescription")}</Description>
            <VehicleSample />
            <EquipmentsOptions />
          </Wrapper>
        </ModalDefault>

        <ModalDefault
          show={openModalCompareContext}
          title={t("Vehicle Options")}
          onHide={onHideModalCompare}
          dialogClassName="width-850"
        >
          <Wrapper hidden={openModalEquipamentsOptionsContext}>
            <Description>{t("CompareVehicleModalDescription")}</Description>
            <CompareVehicles />
          </Wrapper>
        </ModalDefault>
      </VehicleCompareProvider>
    </>
  );
};
