import { Combo } from "c4u-web-components";
import React, { useContext } from "react";
import { SessionContext } from "../../contexts";
import {
  AnonymousModel,
  RouterParam,
  VehicleBrandModels,
  VehicleSearch,
} from "../../models";

interface SessionProviderState {
  vehicleSearchContext: VehicleSearch[];
  setVehicleSearchContext: React.Dispatch<VehicleSearch[]>;
  vehicleBrandContext: VehicleBrandModels[];
  setVehicleBrandContext: React.Dispatch<VehicleBrandModels[]>;
  routerParamContext: RouterParam[];
  addRouterParamContext: (key: string, value: string) => void;
  globalState: string;
  setGlobalSate: React.Dispatch<React.SetStateAction<string>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  priceTypeComboContext: Combo[];
  anonymousContext: AnonymousModel | undefined;
  setAnonymousContext: React.Dispatch<AnonymousModel | undefined>;
}

export const useSessionContext = () => {
  const context = useContext<SessionProviderState>(SessionContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
