import { Img } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import { NumberHelper } from "../../../../helper";
import {
  usePriceContext,
  useSearchContext,
  useSessionContext,
  useStateStorage,
  useVehicleCompareContext,
} from "../../../../hooks";
import KbbLogo from "./../../../assets/images/kbb-logo.png";
import {
  BoxPrice,
  BoxPriceImg,
  BoxPriceInfo,
  BoxPriceInfoPrice,
  BoxPriceInfoTitle,
  EmptyResult,
  RowBoxPrice,
} from "./compare-vehicles-prices.molecule.styled";

interface CompareVehiclesPricesProps {
  vehiclePriceId: number | string | undefined;
  isModalOpen?: boolean;
}

export const CompareVehiclesPrices = (
  props: CompareVehiclesPricesProps
): JSX.Element => {
  const { getStateStorage } = useStateStorage();

  const { priceTypeComboContext } = useSessionContext();
  const {
    vehicleGradeSelected,
    continueProcess,
    kmsVC,
    equipmentsColorVC,
    equipmentsPriceVC,
    equipmentsIdsVC,
  } = usePriceContext();
  const { vehiclesVCContext, GetVehiclePricesVCContext } =
    useVehicleCompareContext();
  const { vehicleContext, setVehicleContext } = useSearchContext();

  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [clearPrices, setClearPrices] = useState<boolean>(true);
  const [vehiclePrices, setVehiclePrices] = useState<number[]>([]);

  useEffect(() => {
    setClearPrices(!props.isModalOpen);
  }, [props.isModalOpen]);

  const getVehiclePriceAsync = useCallback(
    async (
      pricesParam: number[],
      colorsParam: number[],
      kmsParam: string[],
      equipmentsIdsParam: number[][]
    ) => {
      if (
        vehiclesVCContext?.length &&
        vehicleGradeSelected &&
        props.vehiclePriceId !== undefined
      ) {
        const prices = [];
        const state = await getStateStorage();

        let i = 0;
        for await (const v of vehiclesVCContext) {
          const vehiclePrice = await GetVehiclePricesVCContext(
            v,
            !!kmsParam[i] ? Number(kmsParam[i]) : null,
            Number(props.vehiclePriceId),
            state,
            Number(vehicleGradeSelected.value),
            pricesParam[i],
            colorsParam[i],
            equipmentsIdsParam[i]
          );

          prices.push(vehiclePrice.fpp);
          i += 1;
        }
        if (clearPrices) {
          prices.splice(1, 2);
        }
        setVehiclePrices(prices);
      }
    },
    [
      GetVehiclePricesVCContext,
      getStateStorage,
      vehicleGradeSelected,
      props.vehiclePriceId,
      vehiclesVCContext,
      clearPrices,
    ]
  );

  useEffect(() => {
    if (firstLoad && vehiclesVCContext?.length) {
      getVehiclePriceAsync(
        equipmentsPriceVC,
        equipmentsColorVC,
        kmsVC,
        equipmentsIdsVC
      );
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesVCContext]);

  useEffect(() => {
    if (continueProcess === false) {
      getVehiclePriceAsync(
        equipmentsPriceVC,
        equipmentsColorVC,
        kmsVC,
        equipmentsIdsVC
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueProcess]);

  useEffect(() => {
    const firstVehicle = vehiclesVCContext[0];
    if (firstVehicle && vehicleContext && firstVehicle.id !== vehicleContext.id)
      setVehicleContext(vehiclesVCContext[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesVCContext]);

  useEffect(() => {
    if (!firstLoad && vehiclesVCContext?.length)
      getVehiclePriceAsync(
        equipmentsPriceVC,
        equipmentsColorVC,
        kmsVC,
        equipmentsIdsVC
      );
  }, [props.vehiclePriceId, vehiclesVCContext]); // eslint-disable-line react-hooks/exhaustive-deps

  const items = useMemo(() => {
    return ["-", "-", "-"];
  }, []);

  return (
    <RowBoxPrice>
      {items.map((m, i) => {
        return (
          <Col key={`Col-${i}`} className={"m-auto"} sm>
            {vehiclePrices[i] ? (
              <BoxPrice key={`BoxPrice-${i}`}>
                <BoxPriceImg
                  className={"d-none d-md-block"}
                  key={`BoxPriceImg-${i}`}
                >
                  <Img key={`Img-${i}`} src={KbbLogo} />
                </BoxPriceImg>
                <BoxPriceInfo key={`BoxPriceInfo-${i}`}>
                  <BoxPriceInfoTitle key={`BoxPriceInfoTitle-${i}`}>
                    {
                      priceTypeComboContext?.find(
                        (f) => f.value === props.vehiclePriceId
                      )?.title
                    }
                  </BoxPriceInfoTitle>
                  <BoxPriceInfoPrice key={`BoxPriceInfoPrice-${i}`}>
                    R$ {NumberHelper.toFormatString(vehiclePrices[i], 2)}
                  </BoxPriceInfoPrice>
                </BoxPriceInfo>
              </BoxPrice>
            ) : (
              <Col key={`Col-2-${i}`} className={"m-auto"}>
                <EmptyResult key={`EmptyResult-${i}`}>{m}</EmptyResult>
              </Col>
            )}
          </Col>
        );
      })}
    </RowBoxPrice>
  );
};
