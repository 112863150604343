import {
  ButtonSecondary,
  Combo,
  DropdownDefault,
  InputDefault,
  LinkGroup,
  LinkGroupItem,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import {
  useKbb,
  usePriceContext,
  useSessionContext,
  useStateStorage,
  useVehicleSearch,
} from "../../../../hooks";
import {
  PriceAdvisorRequest,
  Vehicle,
  VehiclePriceTypeIdEnum,
} from "../../../../models";
import {
  ColOptions,
  ColPriceAdvisor,
  DivBoxPrice,
  DivButtonEditOptions,
  DivFieldText,
  DivPriceAdvisor,
  DivPriceAdvisorPlaceHolder,
  DivTextAdivice,
  Opacity,
  Wrapper,
} from "./price-advisor-view.molecule.style";

interface PriceAdvisorViewProps {
  vehicle: Vehicle;
  vehicleSearchId: number;
  isAuthenticated?: boolean;
}

export const PriceAdvisorView = (props: PriceAdvisorViewProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    setOpenModalEquipamentsOptionsContext,
    kms,
    setKms,
    vehicleGrades,
    setVehicleGrades,
    vehicleGradeSelected,
    setVehicleGradeSelected,
    equipmentsPrice,
    equipmentsColor,
    setContinueProcess,
    continueProcess,
    setRequestPrice,
    setPriceTypeSelectedContext,
    vehiclePriceTypeIdContext,
    setVehiclePriceTypeIdContext,
    equipmentsIds,
  } = usePriceContext();

  const { GetVehicleSearch } = useVehicleSearch();

  const getLatestSearch = useCallback(() => {
    GetVehicleSearch(5, 0, 0).then((resp) =>
      setAllHistoryStorage(resp.data.filter((f) => f.vehicle))
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { getStateStorage, setAllHistoryStorage } = useStateStorage();

  const { anonymousContext } = useSessionContext();

  const dataItems = useMemo(() => {
    return [
      {
        title: t("Zero KM Price"),
        value: VehiclePriceTypeIdEnum.ZeroKm,
        disabled: !props.vehicle?.zeroKM,
      },
      {
        title: t("Dealer Price"),
        value: VehiclePriceTypeIdEnum.Dealer,
        disabled: false,
      },
      {
        title: t("Private Price"),
        value: VehiclePriceTypeIdEnum.Private,
        disabled: false,
      },
      {
        title: t("Trade-In Price"),
        value: VehiclePriceTypeIdEnum.TradeIn,
        disabled: false,
      },
    ] as LinkGroupItem[];
  }, [props.vehicle, t]);

  const { GetAllVehicleGrade, GetPriceAdvisor } = useKbb();

  const [priceTypeComboSeleted, setPriceTypeComboSeleted] =
    useState<LinkGroupItem>(
      dataItems[vehiclePriceTypeIdContext] ?? dataItems[1]
    );
  const [priceAdvisor, setPriceAdvisor] = useState<string>();
  const [selectedState, setSelectedState] = useState<number>();
  const [vehicleSearchId, setvehicleSearchId] = useState<number>(
    props.vehicleSearchId
  );

  useEffect(() => {
    setvehicleSearchId(props.vehicleSearchId);
  }, [props.vehicleSearchId]);

  const getVehicleGradesAsync = useCallback(async () => {
    try {
      const state = await getStateStorage();
      if (state) setSelectedState(state);

      const vehicleGrade = await GetAllVehicleGrade();
      const vehicleGradeCombo = vehicleGrade?.map(
        (vg) =>
          new Combo({
            title: t(vg.name),
            value: vg.id,
          })
      );
      setVehicleGrades(vehicleGradeCombo);

      if (vehicleGradeCombo?.length > 1 && !vehicleGradeSelected?.value) {
        setVehicleGradeSelected(vehicleGradeCombo[1]);
      }
    } catch (e) {
      console.log("erro price advisor view molecule", e);
    }
  }, [
    getStateStorage,
    GetAllVehicleGrade,
    setVehicleGrades,
    vehicleGradeSelected?.value,
    t,
    setVehicleGradeSelected,
  ]);

  const getPriceAdvisorAsync = useCallback(async () => {
    const request = {
      vehicleID: props.vehicle.id,
      vehiclePriceTypeID: priceTypeComboSeleted.value,
      mileage: !kms ? props.vehicle.avMileage : Number(kms),
      year: props.vehicle.year,
      locationStateID: selectedState,
      vehicleGradeID: vehicleGradeSelected?.value,
      vehicleSearchId: vehicleSearchId,
    } as PriceAdvisorRequest;

    if (equipmentsPrice > 0) request.equipmentCost = equipmentsPrice;
    if (equipmentsColor) request.colorID = equipmentsColor;
    if (equipmentsIds?.length) request.equipmentsIds = equipmentsIds;

    setRequestPrice(request);

    const price = await GetPriceAdvisor(request);
    setTimeout(() => setPriceAdvisor(price.content), 300);
    setvehicleSearchId(price.vehicleSearchId);
  }, [
    priceTypeComboSeleted,
    props.vehicle,
    vehicleGradeSelected,
    kms,
    selectedState,
    equipmentsPrice,
    equipmentsColor,
    equipmentsIds,
    GetPriceAdvisor,
    vehicleSearchId,
    setRequestPrice,
  ]);

  const handleChangeKm = (e: any) => {
    setKms(e.target.value?.replace(/[^\d]/g, ""));
  };

  const conditionPriceAdvisior = useMemo(() => {
    return (
      props.vehicle &&
      props.vehicle.id &&
      priceTypeComboSeleted &&
      vehicleGradeSelected &&
      selectedState
    );
  }, [
    priceTypeComboSeleted,
    vehicleGradeSelected,
    props.vehicle,
    selectedState,
  ]);

  useEffect(() => {
    setPriceAdvisor(undefined);
  }, [vehicleGradeSelected, priceTypeComboSeleted, equipmentsPrice, t]);

  useEffect(() => {
    if (props.vehicle && !kms) setKms(props.vehicle.avMileage.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vehicle, setKms]);

  useEffect(() => {
    if (
      conditionPriceAdvisior &&
      props.isAuthenticated &&
      !anonymousContext?.isAnonymous
    ) {
      getPriceAdvisorAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conditionPriceAdvisior,
    priceTypeComboSeleted,
    vehicleGradeSelected,
    props.isAuthenticated,
    anonymousContext,
  ]);

  useEffect(() => {
    if (anonymousContext?.isAnonymous && conditionPriceAdvisior) {
      getPriceAdvisorAsync();
    }
  }, [conditionPriceAdvisior, priceTypeComboSeleted, vehicleGradeSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (continueProcess === false) {
      getPriceAdvisorAsync();
      setContinueProcess(undefined);
    }
  }, [
    continueProcess,
    getPriceAdvisorAsync,
    setContinueProcess,
    props.isAuthenticated,
  ]);

  useEffect(() => {
    setPriceTypeSelectedContext(priceTypeComboSeleted);
    setVehiclePriceTypeIdContext(
      parseInt(priceTypeComboSeleted.value.toString())
    );
  }, [
    priceTypeComboSeleted,
    setPriceTypeSelectedContext,
    setVehiclePriceTypeIdContext,
  ]);

  useEffect(() => {
    getVehicleGradesAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.vehicle && conditionPriceAdvisior && priceAdvisor) {
      const request = {
        vehicleID: props.vehicle.id,
        vehiclePriceTypeID: priceTypeComboSeleted.value,
        mileage: !kms ? props.vehicle.avMileage : Number(kms),
        year: props.vehicle.year,
        locationStateID: selectedState,
        vehicleGradeID: vehicleGradeSelected?.value,
        vehicleSearchId: vehicleSearchId,
      } as PriceAdvisorRequest;

      if (equipmentsPrice > 0) request.equipmentCost = equipmentsPrice;
      if (equipmentsColor) request.colorID = equipmentsColor;
      if (equipmentsIds?.length) request.equipmentsIds = equipmentsIds;

      setRequestPrice(request);

      const timer = setTimeout(() => {
        GetPriceAdvisor(request).then((payload) => {
          setPriceAdvisor(payload.content);
          setvehicleSearchId(payload.vehicleSearchId);
        });
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [kms]); // eslint-disable-line react-hooks/exhaustive-deps

  const priceTypeIsZeroKm =
    priceTypeComboSeleted?.value === VehiclePriceTypeIdEnum.ZeroKm;
  const priceTypeIsDealer =
    priceTypeComboSeleted?.value === VehiclePriceTypeIdEnum.Dealer;
  const fixedVehicleConditions = priceTypeIsZeroKm || priceTypeIsDealer;

  useEffect(() => {
    if (fixedVehicleConditions && !!vehicleGrades[1])
      setVehicleGradeSelected(vehicleGrades[1]);
  }, [fixedVehicleConditions, setVehicleGradeSelected, vehicleGrades]);

  useEffect(() => {
    if (priceAdvisor && anonymousContext?.isAnonymous) {
      const divPrice = document.getElementById("PriceAdvisorSVG");
      const a = document.createElement("a");
      a.href =
        anonymousContext?.plugin === "4Rodas"
          ? "https://www.kbb.com.br/?utm_source=quatrorodas.abril.com.br&utm_medium=referral&utm_campaign=api_quatrorodasenviar"
          : "https://www.kbb.com.br/";
      a.target = "_blank";
      a.className = "linkKbbPriceAdvisor";
      divPrice?.append(a);
    }
  }, [priceAdvisor, anonymousContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.isAuthenticated && vehicleSearchId !== 0) {
      getLatestSearch();
    }
  }, [vehicleSearchId, props.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <LinkGroup
        data={dataItems}
        value={priceTypeComboSeleted}
        onChange={setPriceTypeComboSeleted}
      />

      <DivBoxPrice>
        <Row>
          <ColOptions lg={5}>
            <Opacity fade={fixedVehicleConditions}>
              <DivFieldText>{t("VehicleConditions")}</DivFieldText>
              <div>
                <DropdownDefault
                  disabled={fixedVehicleConditions}
                  data={vehicleGrades}
                  selectText={t("Select")}
                  value={vehicleGradeSelected}
                  searchField={false}
                  onChange={setVehicleGradeSelected}
                />
              </div>
            </Opacity>
            <Opacity fade={priceTypeIsZeroKm}>
              <DivFieldText>{t("Mileage")}</DivFieldText>
              <div>
                <InputDefault
                  disabled={priceTypeIsZeroKm}
                  type="text"
                  maxLength={8}
                  value={priceTypeIsZeroKm ? 0 : kms}
                  onChange={handleChangeKm}
                />
              </div>
              <DivTextAdivice>
                {t("MileageFeature").replace(
                  "{0}",
                  priceTypeIsZeroKm
                    ? "0"
                    : props.vehicle?.avMileage === undefined
                    ? "-"
                    : props.vehicle?.avMileage.toString()
                )}
              </DivTextAdivice>
            </Opacity>

            <DivButtonEditOptions>
              <ButtonSecondary
                onClick={(e) => setOpenModalEquipamentsOptionsContext(true)}
              >
                {t("Edit Options")}
              </ButtonSecondary>
            </DivButtonEditOptions>
          </ColOptions>

          <ColPriceAdvisor lg={7}>
            {!priceAdvisor ? (
              <DivPriceAdvisorPlaceHolder>
                {`${t("Loading")}...`}
              </DivPriceAdvisorPlaceHolder>
            ) : (
              <DivPriceAdvisor
                dangerouslySetInnerHTML={{ __html: priceAdvisor }}
              />
            )}
          </ColPriceAdvisor>
        </Row>
      </DivBoxPrice>
    </Wrapper>
  );
};
