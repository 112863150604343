import { Combo } from "c4u-web-components";
import { useContext } from "react";
import { VehicleCompareContext } from "../../contexts";
import { Vehicle, VehiclePrices } from "../../models";
import { Equipment } from "../../models/apis/kbb/vehicle-equipment.model";

interface VehicleCompareProviderState {
  vehiclesVCContext: Vehicle[];
  setVehiclesVCContext: React.Dispatch<React.SetStateAction<Vehicle[]>>;
  GetAllEquipmentsVCContext: () => Promise<Equipment[]>;
  GetAllYearsComboAsync: () => Promise<Combo[]>;
  yearsVCContext: Combo[];
  GetAllBrandComboAsync: () => Promise<Combo[]>;
  brandsVCContext: Combo[];
  GetVehiclePricesVCContext: (
    vehicle: Vehicle,
    kms: number | null,
    vehiclePriceTypeId: number,
    stateId: number,
    vehicleGradeId: number,
    equipmentsPrice: number,
    equipmentsColor: number | null,
    equipmentsIds: number[]
  ) => Promise<VehiclePrices>;
}

export const useVehicleCompareContext = () => {
  const context = useContext<VehicleCompareProviderState>(
    VehicleCompareContext
  );

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
