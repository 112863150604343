import styled from "styled-components";
import { Img } from "c4u-web-components";

interface IOpacityDivProps {
  fade?: boolean;
}

const DivBoxPrice = styled.div`
  min-height: 491px;
  background-color: ${(props) => props.theme.colors.Background};
  border: 1px solid ${(props) => props.theme.colors.DarkGray};
  border-radius: 0px 0px 4px 4px;
  border-top: 0;
  padding: 15px;
`;

const ImgStyled = styled(Img)`
  width: 100%;
`;

const TitleVehicle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.BlueNavy};
  line-height: 30px;
  margin: 0 0 8px 0;
`;

const SubTitleVehicle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 22px;
  margin: 0 0 30px 0;
`;

const LabelField = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 22px;
  margin: 0 0 8px 0;
`;

const AdviceKm = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 16px;
  margin: 0 0 32px 0;
`;

const Opacity = styled.div<IOpacityDivProps>`
  opacity: ${(props) => (props.fade ? 0.35 : 1)};
`;

export {
  DivBoxPrice,
  ImgStyled as Img,
  TitleVehicle,
  SubTitleVehicle,
  LabelField,
  AdviceKm,
  Opacity,
};
