import {
  AccordionDefault,
  AccordionDefaultItem,
  Combo,
  CoxIcon,
  DropdownDefault,
  Img,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import {
  useKbb,
  usePriceContext,
  useSearchContext,
  useSessionContext,
  useVehicleCompareContext,
} from "../../../../hooks";
import { Equipment } from "../../../../models";
import {
  CompareVehiclesCard,
  CompareVehiclesInfo,
  CompareVehiclesPrices,
} from "../../../molecules";
import KbbLogo from "./../../../assets/images/kbb-logo.png";
import {
  CardInfoDescription,
  CardInfoHeader,
  CardInfoTitle,
  CardStyled,
  CardStyledInfo,
  DivCategoryTitle,
  RowPrice,
  Wrapper,
} from "./compare-vehicles.organism.style";

export const CompareVehicles = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    GetAllEquipments,
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetVehicle,
  } = useKbb();

  const { priceTypeComboContext } = useSessionContext();
  const { vehicleContext } = useSearchContext();
  const {
    priceTypeSelectedContext,
    openModalCompareContext,
    kmsVC,
    equipmentsColorTagVC,
    equipmentsColorVC,
    equipmentsIdsVC,
    equipmentsPriceVC,
    setKmsVC,
    setEquipmentsColorTagVC,
    setEquipmentsColorVC,
    setEquipmentsIdsVC,
    setEquipmentsPriceVC,
  } = usePriceContext();
  const {
    vehiclesVCContext,
    setVehiclesVCContext,
    GetAllEquipmentsVCContext,
    GetAllYearsComboAsync,
    GetAllBrandComboAsync,
    yearsVCContext,
    brandsVCContext,
  } = useVehicleCompareContext();

  const [vehicleLocalPriceSelected, setVehicleLocalPriceSelected] = useState(
    priceTypeSelectedContext
  );
  const [renderBodyEquips, setRenderBodyEquips] = useState<JSX.Element>();

  const [yearSelected1, setYearSelected1] = useState<Combo>();
  const [yearSelected2, setYearSelected2] = useState<Combo>();
  const [yearSelected3, setYearSelected3] = useState<Combo>();

  const [brandSelected1, setBrandSelected1] = useState<Combo>();
  const [brandSelected2, setBrandSelected2] = useState<Combo>();
  const [brandSelected3, setBrandSelected3] = useState<Combo>();

  const [modelSelected1, setModelSelected1] = useState<Combo>();
  const [modelsSelected1, setModelsSelected1] = useState<Combo[]>();
  const [modelSelected2, setModelSelected2] = useState<Combo>();
  const [modelsSelected2, setModelsSelected2] = useState<Combo[]>();
  const [modelSelected3, setModelSelected3] = useState<Combo>();
  const [modelsSelected3, setModelsSelected3] = useState<Combo[]>();

  const [versionSelected1, setVersionSelected1] = useState<Combo>();
  const [versionsSelected1, setVersionsSelected1] = useState<Combo[]>();
  const [versionSelected2, setVersionSelected2] = useState<Combo>();
  const [versionsSelected2, setVersionsSelected2] = useState<Combo[]>();
  const [versionSelected3, setVersionSelected3] = useState<Combo>();
  const [versionsSelected3, setVersionsSelected3] = useState<Combo[]>();

  const renderBodyDatasheet = useMemo(() => {
    if (!vehiclesVCContext.length) return;

    return (
      <>
        <CompareVehiclesInfo
          title={`${t("CC")}`}
          values={vehiclesVCContext?.flatMap((f) => f.cc.toString())}
          key={`cc`}
          unic={`cc`}
        />
        <CompareVehiclesInfo
          title={t("HP")}
          values={vehiclesVCContext?.flatMap((f) => f.hp.toString())}
          key={`hp`}
          unic={`hp`}
        />
        <CompareVehiclesInfo
          title={t("Maximum Speed")}
          values={vehiclesVCContext?.flatMap((f) => f.maximumSpeed.toString())}
          key={`maximumSpeed`}
          unic={`maximumSpeed`}
        />
        <CompareVehiclesInfo
          title={t("Acceleration 0 to 100")}
          values={vehiclesVCContext?.flatMap((f) =>
            f.acceleration0to100.toString()
          )}
          key={`acceleration0to100`}
          unic={`acceleration0to100`}
        />
      </>
    );
  }, [vehiclesVCContext, t]);

  const renderBodyFuelConsum = useMemo(() => {
    if (!vehiclesVCContext.length) return;
    return (
      <>
        <CompareVehiclesInfo
          title={`${t("Type")}`}
          values={vehiclesVCContext?.flatMap((f) => f.fuelTypeName.toString())}
          key={`cc`}
          unic={`cc`}
        />
        <CompareVehiclesInfo
          title={`${t("Urban Consumption")} (${t("km_l")})`}
          values={vehiclesVCContext?.flatMap((f) =>
            f.urbanConsumption.toString()
          )}
          key={`urbanConsumption`}
          unic={`urbanConsumption`}
        />
        <CompareVehiclesInfo
          title={`${t("Consumption")} (${t("km_l")})`}
          values={vehiclesVCContext?.flatMap((f) =>
            f.combinedConsumption.toString()
          )}
          key={`combinedConsumption`}
          unic={`combinedConsumption`}
        />
        <CompareVehiclesInfo
          title={`${t("Emissions CO2")} (${t("g_km")})`}
          values={vehiclesVCContext?.flatMap((f) => f.emissionsCO2.toString())}
          key={`emissionsCO2`}
          unic={`emissionsCO2`}
        />
      </>
    );
  }, [vehiclesVCContext, t]);

  const renderBodyTransmission = useMemo(() => {
    if (!vehiclesVCContext.length) return;
    return (
      <>
        <CompareVehiclesInfo
          title={`${t("Transmission")}`}
          values={vehiclesVCContext?.flatMap((f) =>
            f.transmissionTypeName.toString()
          )}
          key={`transmissionTypeName`}
          unic={`transmissionTypeName`}
        />
      </>
    );
  }, [vehiclesVCContext, t]);

  const equipmentsCar = useCallback(async () => {
    let carsEquips = [] as Equipment[][];
    for await (const vehicle of vehiclesVCContext) {
      if (!vehicle) {
        carsEquips.push([]);
      } else {
        const equipsCar = await GetAllEquipments(vehicle.id, vehicle.year);
        const allEquipsCar = equipsCar
          ?.map((m) => m.subCategory.map((sb) => sb.equipments))
          ?.flatMap((fm) => fm.flatMap((f) => f));
        carsEquips.push(allEquipsCar);
      }
    }

    return carsEquips;
  }, [vehiclesVCContext, GetAllEquipments]);

  const renderBodyEquipsAsync = useCallback(async () => {
    if (!vehiclesVCContext.length) return;

    const allEquipments = await GetAllEquipmentsVCContext();

    const carsEquips = await equipmentsCar();

    const items = allEquipments.map((equip, i) => {
      const values = carsEquips.map((m) =>
        m.find(
          (f) => f.equipmentID === equip.equipmentID && f.flagOptionID === 0
        ) ? (
          <CoxIcon name="checked" />
        ) : (
          <div>-</div>
        )
      );

      const result = (
        <CompareVehiclesInfo
          title={`${t(equip.equipmentSubCategoryName ?? equip.name)}`}
          values={values}
          key={`${i}-${equip.name}`}
          unic={`${i}-${equip.name}`}
        />
      );

      return result;
    });

    setRenderBodyEquips(<div>{items.map((m) => m)}</div>);
  }, [vehiclesVCContext, t, equipmentsCar, GetAllEquipmentsVCContext]);

  const accordionItems = useMemo((): AccordionDefaultItem[] => {
    return [
      { title: t("Datasheet"), body: renderBodyDatasheet },
      { title: t("Fuel / Consum"), body: renderBodyFuelConsum },
      { title: t("Transmission"), body: renderBodyTransmission },
      { title: t("Equipments and instruments"), body: renderBodyEquips },
    ] as AccordionDefaultItem[];
  }, [
    t,
    renderBodyDatasheet,
    renderBodyFuelConsum,
    renderBodyEquips,
    renderBodyTransmission,
  ]);

  const loadYears = useCallback(() => {
    return GetAllYearsComboAsync();
  }, [GetAllYearsComboAsync]);

  const loadBrands = useCallback(() => {
    return GetAllBrandComboAsync();
  }, [GetAllBrandComboAsync]);

  const handleRemoveVehicle = useCallback(
    (cardIndex: 0 | 1 | 2) => {
      const kmsVCLocal = [...kmsVC];
      const equipmentsColorVCLocal = [...equipmentsColorVC];
      const equipmentsColorTagVCLocal = [...equipmentsColorTagVC];
      const equipmentsIdsVCLocal = [...equipmentsIdsVC];
      const equipmentsPriceVCLocal = [...equipmentsPriceVC];
      const vehiclesVCContextLocal = [...vehiclesVCContext];
      kmsVCLocal.splice(cardIndex, 1);
      equipmentsColorVCLocal.splice(cardIndex, 1);
      equipmentsColorTagVCLocal.splice(cardIndex, 1);
      equipmentsIdsVCLocal.splice(cardIndex, 1);
      equipmentsPriceVCLocal.splice(cardIndex, 1);
      vehiclesVCContextLocal.splice(cardIndex, 1);
      setKmsVC(kmsVCLocal);
      setEquipmentsColorVC(equipmentsColorVCLocal);
      setEquipmentsColorTagVC(equipmentsColorTagVCLocal);
      setEquipmentsIdsVC(equipmentsIdsVCLocal);
      setEquipmentsPriceVC(equipmentsPriceVCLocal);
      setVehiclesVCContext(vehiclesVCContextLocal);
    },
    [
      vehiclesVCContext,
      equipmentsColorTagVC,
      equipmentsColorVC,
      equipmentsIdsVC,
      equipmentsPriceVC,
      kmsVC,
      setVehiclesVCContext,
      setEquipmentsColorTagVC,
      setEquipmentsColorVC,
      setEquipmentsIdsVC,
      setEquipmentsPriceVC,
      setKmsVC,
    ]
  );

  useEffect(() => {
    if (brandSelected1 && yearSelected1) {
      GetAllVehicleModelByBrand(
        Number(brandSelected1?.value),
        Number(yearSelected1?.value)
      ).then((t) => {
        setModelsSelected1(
          t.map(
            (m) =>
              new Combo({
                title: m.name,
                value: m.id,
              })
          )
        );
      });
    }
  }, [brandSelected1, yearSelected1]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (brandSelected1 && yearSelected1 && modelSelected1) {
      GetAllVehicleVersion(
        Number(modelSelected1?.value),
        Number(yearSelected1?.value)
      ).then((t) => {
        setVersionsSelected1(
          t.map(
            (m) =>
              new Combo({
                title: m.name,
                value: m.kbbid,
              })
          )
        );
      });
    }
  }, [brandSelected1, yearSelected1, modelSelected1]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (brandSelected2 && yearSelected2) {
      GetAllVehicleModelByBrand(
        Number(brandSelected2?.value),
        Number(yearSelected2?.value)
      ).then((t) => {
        setModelsSelected2(
          t.map(
            (m) =>
              new Combo({
                title: m.name,
                value: m.id,
              })
          )
        );
      });
    }
  }, [brandSelected2, yearSelected2]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (brandSelected2 && yearSelected2 && modelSelected2) {
      GetAllVehicleVersion(
        Number(modelSelected2?.value),
        Number(yearSelected2?.value)
      ).then((t) => {
        setVersionsSelected2(
          t.map(
            (m) =>
              new Combo({
                title: m.name,
                value: m.kbbid,
              })
          )
        );
      });
    }
  }, [brandSelected2, yearSelected2, modelSelected2]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (brandSelected3 && yearSelected3) {
      GetAllVehicleModelByBrand(
        Number(brandSelected3?.value),
        Number(yearSelected3?.value)
      ).then((t) => {
        setModelsSelected3(
          t.map(
            (m) =>
              new Combo({
                title: m.name,
                value: m.id,
              })
          )
        );
      });
    }
  }, [brandSelected3, yearSelected3]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (brandSelected3 && yearSelected3 && modelSelected3) {
      GetAllVehicleVersion(
        Number(modelSelected3?.value),
        Number(yearSelected3?.value)
      ).then((t) => {
        setVersionsSelected3(
          t.map(
            (m) =>
              new Combo({
                title: m.name,
                value: m.kbbid,
              })
          )
        );
      });
    }
  }, [brandSelected3, yearSelected3, modelSelected3]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (versionSelected1) {
      GetVehicle(Number(versionSelected1?.value)).then((t) => {
        const vehicles = [...vehiclesVCContext];
        vehicles[0] = t;
        setVehiclesVCContext(vehicles);
      });
    }
  }, [versionSelected1]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (versionSelected2) {
      GetVehicle(Number(versionSelected2?.value)).then((t) => {
        const vehicles = [...vehiclesVCContext];
        vehicles[1] = t;
        setVehiclesVCContext(vehicles);
      });
    }
  }, [versionSelected2]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (versionSelected3) {
      GetVehicle(Number(versionSelected3?.value)).then((t) => {
        const vehicles = [...vehiclesVCContext];
        vehicles[2] = t;
        setVehiclesVCContext(vehicles);
      });
    }
  }, [versionSelected3]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleContext) {
      //renderBodyEquipsAsync();
      setVehiclesVCContext([vehicleContext]);
      loadYears();
      loadBrands();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!vehiclesVCContext?.length) {
      renderBodyEquipsAsync();
    }
  }, [vehiclesVCContext]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <Row>
        <Col md={3} className={"mb-2"}>
          <CardStyledInfo>
            <Card.Body>
              <CardInfoHeader>
                <Img src={KbbLogo} />
              </CardInfoHeader>
              <CardInfoTitle>{t("CardCompareInfoTitle")}</CardInfoTitle>
              <CardInfoDescription>
                {t("CardCompareInfoDescription")}
              </CardInfoDescription>
            </Card.Body>
          </CardStyledInfo>
        </Col>
        <Col md={3} className={"mb-2"}>
          <CardStyled>
            <Card.Body>
              <CompareVehiclesCard
                vehicle={vehiclesVCContext[0]}
                yearsCombo={yearsVCContext}
                brandCombo={brandsVCContext}
                modelCombo={modelsSelected1}
                versionCombo={versionsSelected1}
                onSelectYear={setYearSelected1}
                onSelectBrand={setBrandSelected1}
                onSelectModel={setModelSelected1}
                onSelectVehicle={setVersionSelected1}
                onRemoveVehicle={handleRemoveVehicle}
                cardIndex={0}
              />
            </Card.Body>
          </CardStyled>
        </Col>
        <Col md={3} className={"mb-2"}>
          <CardStyled>
            <Card.Body>
              <CompareVehiclesCard
                disabled={!vehiclesVCContext[0]}
                vehicle={vehiclesVCContext[1]}
                yearsCombo={yearsVCContext}
                brandCombo={brandsVCContext}
                modelCombo={modelsSelected2}
                versionCombo={versionsSelected2}
                onSelectYear={setYearSelected2}
                onSelectBrand={setBrandSelected2}
                onSelectModel={setModelSelected2}
                onSelectVehicle={setVersionSelected2}
                onRemoveVehicle={handleRemoveVehicle}
                cardIndex={1}
              />
            </Card.Body>
          </CardStyled>
        </Col>
        <Col md={3} className={"mb-2"}>
          <CardStyled>
            <Card.Body>
              <CompareVehiclesCard
                disabled={!vehiclesVCContext[1]}
                vehicle={vehiclesVCContext[2]}
                yearsCombo={yearsVCContext}
                brandCombo={brandsVCContext}
                modelCombo={modelsSelected3}
                versionCombo={versionsSelected3}
                onSelectYear={setYearSelected3}
                onSelectBrand={setBrandSelected3}
                onSelectModel={setModelSelected3}
                onSelectVehicle={setVersionSelected3}
                onRemoveVehicle={handleRemoveVehicle}
                cardIndex={2}
              />
            </Card.Body>
          </CardStyled>
        </Col>
      </Row>

      <RowPrice>
        <Col md={3} className={"mb-2"}>
          <DivCategoryTitle>{t("Price Category")}</DivCategoryTitle>
          <div>
            <DropdownDefault
              data={priceTypeComboContext}
              selectText={t("Select")}
              value={vehicleLocalPriceSelected}
              searchField={false}
              onChange={setVehicleLocalPriceSelected}
            />
          </div>
        </Col>
        <Col md={9} className={"mb-2"}>
          <CompareVehiclesPrices
            vehiclePriceId={vehicleLocalPriceSelected?.value}
            isModalOpen={openModalCompareContext}
          />
        </Col>
      </RowPrice>

      <AccordionDefault items={accordionItems} disableCollapse />
    </Wrapper>
  );
};
