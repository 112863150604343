import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Img } from "c4u-web-components";

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.Platinum};
  padding: 0 15px;
  border-radius: 4px;
  max-width: 487px;
  margin: auto;
  position: relative;
`;
const RowVehicle = styled(Row)`
  height: 140px;
`;

const ColImgVehicle = styled(Col)`
  text-align: center;
  vertical-align: middle;
  line-height: 140px;
`;

const ColVehicleName = styled(Col)`
  background-color: ${(props) => props.theme.colors.WhiteSmoke};
`;

const ImgStyled = styled(Img)`
  max-height: 140px;
  width: 100%;
`;

const CarTitle = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
  line-height: 30px;
  margin: 26px 0 0;
`;

const CarTitlePrev = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 30px;
  margin: 55px 0 0;
`;

const CarSubTitle = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 20px;
  margin: 8px 0 0;
`;

const RowCarFeatures = styled(Row)`
  background-color: ${(props) => props.theme.colors.GhostWhite};
  height: 116px;
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.colors.Platinum};
`;

const RowCarCategory = styled(Row)`
  background-color: ${(props) => props.theme.colors.WhiteSmoke};
  height: 82px;
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.colors.Platinum};
`;

const RowCarRanking = styled(Row)`
  background-color: ${(props) => props.theme.colors.WhiteSmoke};
  height: 76px;
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.colors.Platinum};
  padding-bottom: 15px;
`;

const RowCarRankingReduce = styled(RowCarRanking)`
  padding: 23px 0 0;
`;

const RowInternalRanking = styled(Row)`
  max-width: 100px;
  margin: auto;
`;

const ColCarCategory = styled(Col)`
  margin: auto;
  border-left: 1px solid ${(props) => props.theme.colors.Platinum};
  overflow: hidden;
  padding: 0;
`;

const ColCarRanking = styled(Col)`
  margin: auto;
  overflow: hidden;
`;

const ColCenter = styled(Col)`
  margin: auto;
  padding: 0;
`;

const InternalTitleLabel = styled.span`
  color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
  font-size: 12px;
  font-weight: bold;
  line-height: 21px;
`;

const InternalDescriptionLabel = styled.span`
  color: ${(props) => props.theme.colors.Dark};
  font-size: 14px;
  line-height: 21px;
`;

const DivIcon = styled.div`
  margin: 0 0 12px 0;
`;
const DivColCarFeatureTitle = styled.div`
  color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
  font-size: 12px;
  font-weight: bold;
  line-height: 21px;
`;

const DivColCarFeatureTitleReduced = styled(DivColCarFeatureTitle)`
  display: inline-flex;
`;

const DivColCarFeatureSubTitle = styled.div`
  color: ${(props) => props.theme.colors.Dark};
  font-size: 12px;
  line-height: 21px;
`;

const DivColCarFeatureSubTitleReduced = styled(DivColCarFeatureSubTitle)`
  display: inline-flex;
  font-size: 12px;
  color: ${(props) => props.theme.colors.LapisLazul};
  line-height: 14px;
  cursor: pointer;
`;

const ColCarMotor = styled(Col)`
  text-align: left;
  margin: auto;
`;

const ColLevelRight = styled(Col)`
  text-align: left;
  margin: 0;
  padding: 0 0 0 8px;
`;
const ColLevelLeft = styled(Col)`
  text-align: right;
  margin: 0;
  padding: 0;
`;

const LinkIcon = styled.div`
  cursor: pointer;
  display: inline-flex;
  margin: 0 0 0 8px;
`;

const Sharebox = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.Background};
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0.95;
  top: 0;
  left: 0;
  border-radius: 4px;

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
`;

const ShareboxWrapper = styled.div`
  padding: 0 35px;
  .row {
    margin: 0 0 8px;
  }
  .row-form {
    margin: 0 0 8px;
  }
`;

const ShareSubTitle = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.Dark};
  text-align: center;
  line-height: 20px;
`;

const ShareTitle = styled(ShareSubTitle)`
  font-weight: bold;
`;

const ShareCloseButton = styled.div`
  height: 12px;
  width: 12px;
  color: ${(props) => props.theme.colors.BlueNavy};
  position: absolute;
  top: 16px;
  right: 16px;
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: bold;
  cursor: pointer;
`;

export {
  Wrapper,
  RowVehicle,
  ColImgVehicle,
  ColVehicleName,
  ImgStyled as Img,
  CarTitle,
  CarTitlePrev,
  CarSubTitle,
  RowCarCategory,
  RowCarFeatures,
  RowCarRanking,
  RowCarRankingReduce,
  RowInternalRanking,
  ColCarCategory,
  ColCarRanking,
  InternalTitleLabel,
  InternalDescriptionLabel,
  ColCarMotor,
  ColCenter,
  ColLevelRight,
  ColLevelLeft,
  DivIcon,
  DivColCarFeatureTitle,
  DivColCarFeatureSubTitle,
  DivColCarFeatureTitleReduced,
  DivColCarFeatureSubTitleReduced,
  LinkIcon,
  Sharebox,
  ShareTitle,
  ShareSubTitle,
  ShareboxWrapper,
  ShareCloseButton,
};
