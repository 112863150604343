import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import {
  Combo,
  DropdownCustom,
  LabelDefault,
  SingleSelector,
} from "c4u-web-components";

import {
  DivDropdownStyled,
  Col,
  Row,
  DivText,
} from "./year-selector.molecule.style";

interface YearSelectorProps {
  years: Combo[];
  value?: Combo;
  onChange?: (value: Combo) => void;
}

export const YearSelector: React.FC<YearSelectorProps> = (
  parameters
): JSX.Element => {
  const { t } = useTranslation();
  const isMobileScreen = useMediaQuery({ minWidth: 576 });
  const yearCount = useMemo(() => (isMobileScreen ? 5 : 4), [isMobileScreen]);

  const [yearSelected, setYearSelected] = useState<number | string>();
  const [yearsDropdown, setYearsDropdown] = useState<Combo[] | undefined>();
  const [yearDropdownSelected, setYearDropdownSelected] = useState<
    Combo | undefined
  >();

  const handleClick = (combo: Combo) => {
    setYearSelected(combo.value);
    setYearDropdownSelected(undefined);
    if (parameters.onChange) parameters.onChange(combo);
  };

  const changeDropdownHandle = (combo: Combo) => {
    setYearSelected(combo.value);
    setYearDropdownSelected(combo);
    if (parameters.onChange) parameters.onChange(combo);
  };

  useEffect(() => {
    if (parameters.years?.length > 5)
      setYearsDropdown(parameters.years.slice(yearCount));
    else setYearsDropdown(undefined);
  }, [parameters.years, yearCount]);

  useEffect(() => {
    const valueParam = parameters.value?.value ? parameters.value.value : 0;
    const itemDropdown = yearsDropdown?.find((f) => f.value === valueParam);

    if (valueParam === 0) {
      setYearSelected(valueParam);
      setYearDropdownSelected(parameters.value);
    } else {
      if (itemDropdown) {
        setYearDropdownSelected(parameters.value);
        setYearSelected(undefined);
      } else {
        setYearSelected(valueParam);
        setYearDropdownSelected(undefined);
      }
    }
  }, [parameters.value, yearsDropdown]);

  return (
    <>
      <Row>
        <Col xs={8} sm={10}>
          <DivText>
            <LabelDefault>{t("Select the year of the model")}</LabelDefault>
          </DivText>
        </Col>
        <Col xs={2}>
          {yearsDropdown && (
            <DivText>
              <LabelDefault>{t("Others")}</LabelDefault>
            </DivText>
          )}
        </Col>
      </Row>
      <Row>
        {parameters.years?.slice(0, yearCount).map((year, i) => (
          <Col key={i} xs={2}>
            <SingleSelector
              key={i}
              option={year}
              selected={yearSelected === year.value}
              onClick={handleClick}
            />
          </Col>
        ))}
        <Col xs={2}>
          {yearsDropdown && (
            <DivDropdownStyled selected={yearDropdownSelected !== undefined}>
              <DropdownCustom
                data={yearsDropdown}
                selectText={"..."}
                placeholder={t("Select") + "..."}
                onChange={changeDropdownHandle}
                value={yearDropdownSelected}
              />
            </DivDropdownStyled>
          )}
        </Col>
      </Row>
    </>
  );
};
