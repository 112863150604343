import {
  ButtonPrimary,
  Combo,
  CoxIcon,
  DropdownDefault,
  Img,
} from "c4u-web-components";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { usePriceContext, useVehicleCompareContext } from "../../../../hooks";
import { Vehicle } from "../../../../models";
import {
  CardCarDescription,
  CardCarHeader,
  CardCarSubTitle,
  CardCarTitle,
  CardEmptyIcon,
  CardEmptyText,
  CardNewVehicle,
  CloseButton,
  DivLoading,
  DivTextLoading,
  OptionalsEditBox,
  RowCombo,
  WrapperCombos,
} from "./compare-vehicles-card.molecule.styled";

interface CompareVehiclesCardProps {
  disabled?: boolean;
  vehicle?: Vehicle;
  onRemoveVehicle?(id: number): void;
  yearsCombo?: Combo[];
  brandCombo?: Combo[];
  modelCombo?: Combo[];
  versionCombo?: Combo[];
  onSelectYear?(item: Combo | undefined): void;
  onSelectBrand?(item: Combo | undefined): void;
  onSelectVehicle?(item: Combo | undefined): void;
  onSelectModel?(item: Combo | undefined): void;
  cardIndex: 0 | 1 | 2;
}

export const CompareVehiclesCard = (
  props: CompareVehiclesCardProps
): JSX.Element => {
  const { t } = useTranslation();
  const {
    setOpenModalEquipamentsOptionsContext,
    setEditEquipmentsCompareContext,
    setVehicleCompareCardIndex,
  } = usePriceContext();
  const { vehiclesVCContext } = useVehicleCompareContext();

  const [vehicle, setVehicle] = useState(props.vehicle);
  const [addSearchBox, setAddSearchBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const [year, setYear] = useState<Combo>();
  const [brand, setBrand] = useState<Combo>();
  const [model, setModel] = useState<Combo>();
  const [version, setVersion] = useState<Combo>();

  useEffect(() => {
    setVehicle(props.vehicle);
    setLoading(false);
  }, [props.vehicle]);

  useEffect(() => {
    if (props.disabled) setAddSearchBox(false);
  }, [props.disabled]);

  useEffect(() => {
    if (vehiclesVCContext.length < props.cardIndex + 1) {
      setYear(undefined);
      setBrand(undefined);
      setModel(undefined);
      setVersion(undefined);
    }
  }, [props.cardIndex, vehiclesVCContext.length]);

  useEffect(() => {
    if (props.onSelectYear) props.onSelectYear(year);
    if (props.onSelectBrand) props.onSelectBrand(brand);
    if (props.onSelectModel) props.onSelectModel(undefined);
    if (props.onSelectVehicle) props.onSelectVehicle(undefined);
    setModel(undefined);
    setVersion(undefined);
  }, [year, brand]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.onSelectModel) props.onSelectModel(model);
    if (props.onSelectVehicle) props.onSelectVehicle(undefined);
    setVersion(undefined);
  }, [model]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickOk = () => {
    if (props.onSelectVehicle && version) {
      props.onSelectVehicle(version);
      setLoading(true);
      setAddSearchBox(false);
    }
  };

  const handleClickRemove = () => {
    if (props.onRemoveVehicle && vehicle) {
      props.onRemoveVehicle(props.cardIndex);
      setAddSearchBox(false);
      setYear(undefined);
      setBrand(undefined);
      setModel(undefined);
      setVersion(undefined);
    }
  };

  const handleClickOptional = () => {
    setOpenModalEquipamentsOptionsContext(true);
    setEditEquipmentsCompareContext(true);
    setVehicleCompareCardIndex(props.cardIndex);
  };

  const renderCardSearchCar = () => {
    return (
      <WrapperCombos>
        <CloseButton>
          <span onClick={() => setAddSearchBox(false)}>X</span>
        </CloseButton>
        <RowCombo>
          <Col>
            <DropdownDefault
              data={props.yearsCombo ?? []}
              selectText={t("Year")}
              searchField={true}
              value={year}
              onChange={setYear}
            />
          </Col>
        </RowCombo>
        <RowCombo>
          <Col>
            <DropdownDefault
              data={props.brandCombo ?? []}
              selectText={t("Brand")}
              searchField={true}
              value={brand}
              onChange={setBrand}
            />
          </Col>
        </RowCombo>
        <RowCombo>
          <Col>
            <DropdownDefault
              data={props.modelCombo ?? []}
              selectText={t("Model")}
              searchField={true}
              value={model}
              onChange={setModel}
            />
          </Col>
        </RowCombo>
        <Row>
          <Col>
            <DropdownDefault
              data={props.versionCombo ?? []}
              selectText={t("Version")}
              searchField={true}
              value={version}
              onChange={setVersion}
            />
          </Col>
        </Row>
        <Row>
          <Col className={"mt-2 text-center"}>
            <ButtonPrimary sizex={"sm"} sizey={"thin"} onClick={handleClickOk}>
              OK
            </ButtonPrimary>
          </Col>
        </Row>
      </WrapperCombos>
    );
  };

  return (
    <>
      {vehicle ? (
        <>
          <CardCarHeader onClick={handleClickRemove}>
            <CoxIcon name="trash" />
          </CardCarHeader>
          <CardCarTitle>{vehicle.brandName}</CardCarTitle>
          <CardCarSubTitle
            title={`${vehicle.modelName} ${vehicle.versionName} ${vehicle.year}`}
          >
            {vehicle.modelName} {vehicle.versionName} {vehicle.year}
          </CardCarSubTitle>
          <CardCarDescription>
            <Img src={vehicle.firstMediaCompleteURLMedium} />
          </CardCarDescription>
          <OptionalsEditBox onClick={handleClickOptional}>
            {t("Edit Opcionals")}
          </OptionalsEditBox>
        </>
      ) : (
        <>
          {addSearchBox ? (
            renderCardSearchCar()
          ) : (
            <>
              {loading ? (
                <DivLoading>
                  <DivTextLoading>{t("Loading") + "..."}</DivTextLoading>
                </DivLoading>
              ) : (
                <CardNewVehicle
                  disabled={!!props.disabled}
                  onClick={
                    props.disabled ? () => void 0 : () => setAddSearchBox(true)
                  }
                >
                  <CardEmptyIcon disabled={!!props.disabled}>+</CardEmptyIcon>
                  <CardEmptyText>{t("Add Vehicle to Compare")}</CardEmptyText>
                </CardNewVehicle>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
