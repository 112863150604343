import { useRequest, kbbHttp } from "../config";
import { VehicleSearch } from "../../../models";
import { PagedResponse } from "../../../models/shared/paged-response.model";
import { HttpHelper } from "c4u-web-components";

export const useVehicleSearch = () => {
  const { get, post, deleteCall } = useRequest(kbbHttp, "VehicleSearch");

  const GetVehicleSearch = async (
    recordsToGet: number,
    recordsToSkip: number,
    ordenation: string | number | undefined = 0
  ): Promise<PagedResponse<VehicleSearch>> => {
    const { data } = await get(
      HttpHelper.BuildQueryString({ recordsToGet, recordsToSkip, ordenation })
    );
    return data;
  };

  const PostVehicleSearch = async (
    vehicleSearch: VehicleSearch
  ): Promise<VehicleSearch> => {
    const { data } = await post("", vehicleSearch);
    return data;
  };

  const DeleteVehicleSearch = async (
    vehicleSearchId: number
  ): Promise<void> => {
    await deleteCall(vehicleSearchId.toString());
  };

  return {
    GetVehicleSearch,
    PostVehicleSearch,
    DeleteVehicleSearch,
  };
};
