import styled from "styled-components";
import Col from "react-bootstrap/esm/Col";

interface IOpacityDivProps {
  fade?: boolean;
}

const DivBoxPrice = styled.div`
  min-height: 487px;
  background-color: ${(props) => props.theme.colors.Background};
  border: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  border-radius: 0px 0px 4px 4px;
  border-top: 0;
  padding: 15px;
`;

const ColOptions = styled(Col)`
  padding: 0 20px 0 70px;
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
`;

const ColPriceAdvisor = styled(Col)`
  margin: auto;
`;

const DivFieldText = styled.div`
  margin: 32px 0 8px 0;
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 22px;
`;

const DivPriceAdvisor = styled.div`
  padding: 10px 25px 0 0;
  text-align: center;
`;

const DivPriceAdvisorPlaceHolder = styled.div`
  width: 350px;
  height: 413px;
  padding-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DivTextAdivice = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 16px;
`;

const DivButtonEditOptions = styled.div`
  margin: 17px 0;
`;

const Opacity = styled.div<IOpacityDivProps>`
  opacity: ${(props) => (props.fade ? 0.35 : 1)};
`;

const Wrapper = styled.div`
  margin-top: 21px;
  max-width: 696px;
`;

export {
  DivBoxPrice,
  ColOptions,
  DivFieldText,
  DivPriceAdvisor,
  DivPriceAdvisorPlaceHolder,
  DivTextAdivice,
  ColPriceAdvisor,
  DivButtonEditOptions,
  Opacity,
  Wrapper,
};
