import React from 'react';
import {
    Wrapper,
    Img,
    CarTitlePrev,
    RowVehicle,
    ColImgVehicle,
    ColVehicleName,
} from './vehicle-features.molecule.style';

import { useSearchContext } from '../../../../hooks';

export const VehiclePreview = (): JSX.Element => {
    const { vehicleBrandContext } = useSearchContext();

    const newCarName = vehicleBrandContext.name.replace(" ", "-");
    const URLImg = `https://static.kbb.com.br/Themes/GPS/Images/pt_BR/Brands/${newCarName}.png`;

    return (
        <>
            {vehicleBrandContext &&
                <Wrapper>
                    <RowVehicle>
                        <ColImgVehicle xs={4}>
                            <Img src={URLImg} />
                        </ColImgVehicle>
                        <ColVehicleName xs={8}>
                            <CarTitlePrev>{vehicleBrandContext.name}</CarTitlePrev>
                        </ColVehicleName>
                    </RowVehicle>
                </Wrapper >
            }
        </>
    )
};
