import { Combo, LinkGroupItem } from "c4u-web-components";
import { useContext } from "react";
import { PriceContext } from "../../contexts";
import { PriceAdvisorRequest, VehiclePriceTypeIdEnum } from "../../models";

interface PriceProviderState {
  openModalEquipamentsOptionsContext: boolean;
  setOpenModalEquipamentsOptionsContext: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  kms: string;
  setKms: React.Dispatch<React.SetStateAction<string>>;
  vehicleGrades: Combo[];
  setVehicleGrades: React.Dispatch<React.SetStateAction<Combo[]>>;
  vehicleGradeSelected: Combo | undefined;
  setVehicleGradeSelected: React.Dispatch<
    React.SetStateAction<Combo | undefined>
  >;
  continueProcess: boolean | undefined;
  setContinueProcess: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  equipmentsPrice: number;
  setEquipmentsPrice: React.Dispatch<React.SetStateAction<number>>;
  equipmentsColor: number | undefined;
  setEquipmentsColor: React.Dispatch<React.SetStateAction<number | undefined>>;
  equipmentsColorTag: number | undefined;
  setEquipmentsColorTag: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  equipmentsIds: number[];
  setEquipmentsIds: React.Dispatch<React.SetStateAction<number[]>>;
  openModalCompareContext: boolean;
  setOpenModalCompareContext: React.Dispatch<React.SetStateAction<boolean>>;
  priceTypeSelectedContext: LinkGroupItem;
  setPriceTypeSelectedContext: React.Dispatch<
    React.SetStateAction<LinkGroupItem>
  >;
  editEquipmentsCompareContext: boolean;
  setEditEquipmentsCompareContext: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  requestPrice: PriceAdvisorRequest;
  setRequestPrice: React.Dispatch<React.SetStateAction<PriceAdvisorRequest>>;
  vehiclePriceTypeIdContext: VehiclePriceTypeIdEnum;
  setVehiclePriceTypeIdContext: React.Dispatch<
    React.SetStateAction<VehiclePriceTypeIdEnum>
  >;
  equipmentsPriceVC: number[];
  setEquipmentsPriceVC: React.Dispatch<React.SetStateAction<number[]>>;
  equipmentsColorVC: number[];
  setEquipmentsColorVC: React.Dispatch<React.SetStateAction<number[]>>;
  equipmentsColorTagVC: number[];
  setEquipmentsColorTagVC: React.Dispatch<React.SetStateAction<number[]>>;
  equipmentsIdsVC: number[][];
  setEquipmentsIdsVC: React.Dispatch<React.SetStateAction<number[][]>>;
  vehicleCompareCardIndex: number;
  setVehicleCompareCardIndex: React.Dispatch<React.SetStateAction<number>>;
  kmsVC: string[];
  setKmsVC: React.Dispatch<React.SetStateAction<string[]>>;
}

export const usePriceContext = () => {
  const context = useContext<PriceProviderState>(PriceContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
