import { ButtonPrimary, ButtonSecondary, H2 } from "c4u-web-components";
import React, { useCallback, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  usePriceContext,
  useSearchContext,
  useSessionContext,
} from "../../../../hooks";
import {
  VehicleFeatures,
  VehicleOptions,
  VehiclePreview,
} from "../../../molecules";

export const SearchVehicleFeatures = () => {
  const { t } = useTranslation();
  const { vehicleContext, setVehicleContext, vehicleBrandContext } =
    useSearchContext();
  const { anonymousContext } = useSessionContext();
  const {
    setEquipmentsColor,
    setEquipmentsPrice,
    setEquipmentsIds,
    setKms,
    setVehicleGradeSelected,
  } = usePriceContext();

  useEffect(() => {
    setVehicleContext(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clearPriceContexts = useCallback(() => {
    setEquipmentsColor(undefined);
    setEquipmentsPrice(0);
    setEquipmentsIds([]);
    setKms("");
    setVehicleGradeSelected(undefined);
  }, [
    setEquipmentsColor,
    setEquipmentsIds,
    setEquipmentsPrice,
    setKms,
    setVehicleGradeSelected,
  ]);

  return (
    <Row>
      <Col lg={1} className="mb-3">
        {!anonymousContext?.isAnonymous ? (
          <Link to={"/"}>
            <ButtonSecondary sizex="sm" sizey="thin">
              {t("Voltar")}
            </ButtonSecondary>
          </Link>
        ) : (
          <></>
        )}
      </Col>
      <Col lg={5}>
        <H2>{t("Select a Vehicle")}</H2>
        <VehicleOptions />
      </Col>
      <Col lg={1} className="mb-5"></Col>
      <Col lg={5}>
        {!vehicleContext && vehicleBrandContext && <VehiclePreview />}

        {vehicleContext && (
          <>
            <VehicleFeatures reduced={false} />
            <div className="my-3 text-center">
              <Link
                to={`/price/${vehicleContext.id}${
                  anonymousContext?.anonymousUrlParam ?? ""
                }`}
                onClick={clearPriceContexts}
              >
                <ButtonPrimary>{t("Look at values")}</ButtonPrimary>
              </Link>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};
