import React from "react";
import { CoxIcon } from "c4u-web-components";
import {
  ActiveLine,
  IconBox,
  LabelIdentify,
  Wrapper,
} from "./box-icon-search.molecule.style";
import { SearchTypeConstant } from "../../../../constants";

interface BoxIconSearchProps {
  label: string;
  title?: string;
  icon: SearchTypeConstant;
  active: boolean;
  click?: (event: React.MouseEvent) => void;
}
export const BoxIconSearch = (parameters: BoxIconSearchProps): JSX.Element => {
  const renderIcon = () => {
    switch (parameters.icon) {
      case SearchTypeConstant.TRUCK:
        return <CoxIcon name="truck" isEnabled={parameters.active} />;

      case SearchTypeConstant.MOTORCYCLE:
        return <CoxIcon name="motorcycle" isEnabled={parameters.active} />;

      case SearchTypeConstant.BUS:
        return <CoxIcon name="bus" isEnabled={parameters.active} />;

      case SearchTypeConstant.IMPLEMENT:
        return <CoxIcon name="implement" isEnabled={parameters.active} />;

      case SearchTypeConstant.CARSMALL:
        return <CoxIcon name="car-small" isEnabled={parameters.active} />;

      case SearchTypeConstant.CAR:
      default:
        return <CoxIcon name="car" isEnabled={parameters.active} />;
    }
  };

  return (
    <Wrapper onClick={parameters.click} title={parameters.title}>
      <IconBox>{renderIcon()}</IconBox>
      <LabelIdentify active={parameters.active}>
        {parameters.label}
      </LabelIdentify>
      {parameters.active && <ActiveLine />}
    </Wrapper>
  );
};
