import {
  AccordionDefault,
  AccordionDefaultItem,
  theme,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import {
  useKbb,
  usePriceContext,
  useSearchContext,
  useVehicleCompareContext,
} from "../../../../hooks";
import { SubCategory, VehicleEquipment } from "../../../../models";
import {
  DivContent,
  DivTitleCategory,
  LabelTypeEquipment,
  TextSelectOptions,
  Wrapper,
} from "./equipments-options.molecule.tyle";

export const EquipmentsOptions = (): JSX.Element => {
  const { GetAllEquipments } = useKbb();
  const { t } = useTranslation();
  const { vehicleContext } = useSearchContext();
  const {
    continueProcess,
    setContinueProcess,
    setOpenModalEquipamentsOptionsContext,
    equipmentsColor,
    equipmentsIds,
    setEquipmentsPrice,
    setEquipmentsColor,
    setEquipmentsColorTag,
    setEquipmentsIds,
    equipmentsColorVC,
    equipmentsColorTagVC,
    equipmentsIdsVC,
    equipmentsPriceVC,
    setEquipmentsColorVC,
    setEquipmentsColorTagVC,
    setEquipmentsIdsVC,
    setEquipmentsPriceVC,
    editEquipmentsCompareContext,
    vehicleCompareCardIndex: vcIndex,
    setVehicleCompareCardIndex: setVcIndex,
  } = usePriceContext();
  const { vehiclesVCContext } = useVehicleCompareContext();

  const vehicleContextFacade = useMemo(() => {
    return editEquipmentsCompareContext
      ? vehiclesVCContext[vcIndex]
      : vehicleContext;
  }, [
    editEquipmentsCompareContext,
    vcIndex,
    vehicleContext,
    vehiclesVCContext,
  ]);

  const [showIncludedItem, setShowIncludedItem] = useState(false);

  const [equipments, setEquipments] = useState<AccordionDefaultItem[]>();
  const [colorSelected, setColorSelected] = useState<number>();
  const [colorSelectedTag, setColorSelectedTag] = useState<number>();
  const [vehicleEquipment, setVehicleEquipment] =
    useState<VehicleEquipment[]>();

  const equipmentsColorFacade = useMemo(() => {
    return editEquipmentsCompareContext
      ? equipmentsColorVC[vcIndex]
      : equipmentsColor;
  }, [
    editEquipmentsCompareContext,
    equipmentsColorVC,
    vcIndex,
    equipmentsColor,
  ]);

  const setEquipmentsColorFacade = useMemo(() => {
    if (editEquipmentsCompareContext) {
      return (value: number) => {
        const colors = [...equipmentsColorVC];
        colors[vcIndex] = value;
        setEquipmentsColorVC(colors);
      };
    }
    return setEquipmentsColor;
  }, [
    editEquipmentsCompareContext,
    setEquipmentsColor,
    equipmentsColorVC,
    vcIndex,
    setEquipmentsColorVC,
  ]);

  const setEquipmentsColorTagFacade = useMemo(() => {
    if (editEquipmentsCompareContext) {
      return (value: number) => {
        const colorTags = [...equipmentsColorTagVC];
        colorTags[vcIndex] = value;
        setEquipmentsColorTagVC(colorTags);
      };
    }
    return setEquipmentsColorTag;
  }, [
    editEquipmentsCompareContext,
    setEquipmentsColorTag,
    equipmentsColorTagVC,
    vcIndex,
    setEquipmentsColorTagVC,
  ]);

  const equipmentsIdsFacade = useMemo(() => {
    return editEquipmentsCompareContext
      ? equipmentsIdsVC[vcIndex] ?? []
      : equipmentsIds;
  }, [editEquipmentsCompareContext, equipmentsIdsVC, equipmentsIds, vcIndex]);

  const setEquipmentsIdsFacade = useMemo(() => {
    if (editEquipmentsCompareContext) {
      return (value: number[]) => {
        const ids = [...equipmentsIdsVC];
        ids[vcIndex] = value;
        setEquipmentsIdsVC(ids);
      };
    }
    return setEquipmentsIds;
  }, [
    editEquipmentsCompareContext,
    setEquipmentsIds,
    equipmentsIdsVC,
    vcIndex,
    setEquipmentsIdsVC,
  ]);

  const setEquipmentsPriceFacade = useMemo(() => {
    if (editEquipmentsCompareContext) {
      return (value: number) => {
        const prices = [...equipmentsPriceVC];
        prices[vcIndex] = value;
        setEquipmentsPriceVC(prices);
      };
    }
    return setEquipmentsPrice;
  }, [
    editEquipmentsCompareContext,
    setEquipmentsPrice,
    equipmentsPriceVC,
    vcIndex,
    setEquipmentsPriceVC,
  ]);

  const handleCheck = (checked: boolean | string, id: number, tag: string) => {
    if (checked === "color") {
      setColorSelected(id);
      setColorSelectedTag(parseInt(tag));
      let equipmentsSelected = equipmentsIdsFacade?.filter(
        (f) => f !== colorSelected
      );
      equipmentsSelected.push(id);
      setEquipmentsIdsFacade(equipmentsSelected);
    } else if (checked) {
      const equipmentsAdd = [...equipmentsIdsFacade, id];
      setEquipmentsIdsFacade(equipmentsAdd);
    } else {
      setEquipmentsIdsFacade(equipmentsIdsFacade?.filter((f) => f !== id));
    }
  };

  const doContinueProcess = useCallback(() => {
    try {
      const allEquips = vehicleEquipment
        ?.map((m) => m.subCategory.map((sb) => sb.equipments))
        ?.flatMap((fm) => fm.flatMap((f) => f));
      const equipmentsSelected = allEquips?.filter(
        (f) =>
          f.equipmentCategoryID !== 0 &&
          (equipmentsIdsFacade.includes(f.id) || f.flagOptionID === 0)
      );
      let price = 0;
      equipmentsSelected?.forEach((element) => {
        price += element.price;
      });
      setEquipmentsPriceFacade(price);
      if (colorSelected) setEquipmentsColorFacade(colorSelected);
      if (colorSelectedTag) setEquipmentsColorTagFacade(colorSelectedTag);
    } catch (error) {
      console.log(error);
    } finally {
      setContinueProcess(false);
      setOpenModalEquipamentsOptionsContext(false);
      setVcIndex(0);
    }
  }, [
    vehicleEquipment,
    setEquipmentsPriceFacade,
    colorSelected,
    setEquipmentsColorFacade,
    colorSelectedTag,
    setEquipmentsColorTagFacade,
    equipmentsIdsFacade,
    setContinueProcess,
    setOpenModalEquipamentsOptionsContext,
    setVcIndex,
  ]);

  const formatText = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const renderBody = (id: number, subCategory: SubCategory[]) => {
    return subCategory.map((m) => (
      <div key={`div-${id}-${m.subCategoryID}`}>
        {m.equipments.filter((f) => showIncludedItem || f.flagOptionID !== 0)
          .length > 0 && (
          <DivTitleCategory key={`DivTitleCategory-${id}-${m.subCategoryID}`}>
            {formatText(m.subCategoryName)}
          </DivTitleCategory>
        )}
        {m.equipments.map(
          (e, index) =>
            (showIncludedItem || e.flagOptionID !== 0) && (
              <DivContent
                key={`DivContent-${id}-${m.subCategoryID}-${e.id}-${index}`}
              >
                <Row>
                  <Col xs={9}>
                    <Form.Group
                      key={`Group-${id}-${m.subCategoryID}-${e.id}-${index}`}
                      controlId={`formCheckbox-${id}-${e.id}-${index}`}
                      className="m-0"
                    >
                      <Form.Check
                        key={`Check-${id}-${m.subCategoryID}-${e.id}-${index}`}
                        type={m.subCategoryID !== 0 ? "checkbox" : "radio"}
                        label={formatText(e.name)}
                        checked={
                          !!equipmentsIdsFacade.find((f) => f === e.id) ||
                          e.flagOptionID === 0
                        }
                        disabled={e.flagOptionID === 0}
                        onChange={(ev: any) =>
                          handleCheck(
                            id === 0 ? "color" : ev.target.checked,
                            e.id,
                            e.tag
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                  {m.subCategoryID !== 0 && (
                    <Col className={"text-right"} xs={3}>
                      <LabelTypeEquipment>
                        {e.flagOptionID === 0 ? t("Included") : t("Optional")}
                      </LabelTypeEquipment>
                    </Col>
                  )}
                </Row>
              </DivContent>
            )
        )}
      </div>
    ));
  };

  const getEquipmentsAsync = useCallback(async () => {
    if (vehicleContextFacade) {
      const equipmentsService = await GetAllEquipments(
        vehicleContextFacade.id,
        vehicleContextFacade.year
      );
      setVehicleEquipment(equipmentsService);
    }
  }, [vehicleContextFacade, GetAllEquipments]);

  useEffect(() => {
    if (vehicleEquipment && vehicleEquipment?.length > 0) {
      const items = vehicleEquipment.map((m) => {
        const search =
          m.subCategory.filter(
            (f) =>
              f.equipments.filter(
                (ff) => showIncludedItem || ff.flagOptionID !== 0
              ).length > 0
          ).length > 0;
        if (search)
          return {
            title: m.categoryName,
            body: renderBody(m.categoryID, m.subCategory),
          } as AccordionDefaultItem;
        return {} as AccordionDefaultItem;
      });
      setEquipments(items.filter((f) => f?.title));
    }
  }, [vehicleEquipment, showIncludedItem, equipmentsIdsFacade]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (continueProcess) doContinueProcess();
  }, [continueProcess, doContinueProcess]);

  useEffect(() => {
    if (equipmentsColorFacade) setColorSelected(equipmentsColorFacade);
  }, [equipmentsColorFacade]);

  useEffect(() => {
    setEquipmentsIdsFacade(equipmentsIdsFacade);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEquipmentsAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <Row>
        <Col>
          <TextSelectOptions>{t("Select vehicle options")}</TextSelectOptions>
        </Col>
        <Col className={"text-right"}>
          <Form.Group controlId={`formHideIncluded`} className="m-0">
            <Form.Check
              type="checkbox"
              label={t("Show only edit options")}
              checked={!showIncludedItem}
              onChange={(e: any) => setShowIncludedItem(!e.target.checked)}
            />
          </Form.Group>
        </Col>
      </Row>

      {equipments && (
        <AccordionDefault
          items={equipments}
          disableCollapse
          itemsTitleBackgroudColor={theme.colors.Accordion.Title.BackgroundKbb}
        />
      )}
    </Wrapper>
  );
};
