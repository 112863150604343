import { HomePage, SearchPage, PricePage, HistoryPage } from "../ui/pages";
import { AnonymousTemplate, MainTemplate } from "../ui/templates";

const paths = {
  home: () => `/`,
  search: (param?: string) => `/search/:brandId${param ?? ""}`,
  searchHistory: () => `/search-history`,
  price: (param?: string) => `/price/:vehicleId${param ?? ""}`,
  priceSearch: (param?: string) =>
    `/price/:vehicleId/:vehicleSearchId${param ?? ""}`,
};

const Definition = {
  Anonymous: "/anonymous",
};

const routes = [
  {
    path: paths.home(),
    component: HomePage,
    template: MainTemplate,
    title: "HomePage",
  },
  {
    path: paths.search(),
    component: SearchPage,
    backTo: paths.home(),
    template: MainTemplate,
    title: "SearchPage",
  },
  {
    path: paths.search(Definition.Anonymous),
    component: SearchPage,
    template: AnonymousTemplate,
    title: "SearchPage",
  },
  {
    path: paths.searchHistory(),
    component: HistoryPage,
    backTo: paths.home(),
    template: MainTemplate,
    title: "HistoryPage",
  },
  {
    path: paths.price(),
    component: PricePage,
    backTo: paths.search(),
    template: MainTemplate,
    title: "PricePage",
    pathMatch: "/price/\\d+",
  },
  {
    path: paths.price(Definition.Anonymous),
    component: PricePage,
    backTo: paths.search(Definition.Anonymous),
    template: AnonymousTemplate,
    title: "PricePage",
    pathMatch: "/price/\\d+",
  },
  {
    path: paths.priceSearch(),
    component: PricePage,
    backTo: paths.search(),
    template: MainTemplate,
    title: "PricePage",
    pathMatch: "/price/\\d+/\\d+",
  },
  {
    path: paths.priceSearch(Definition.Anonymous),
    component: PricePage,
    backTo: paths.search(Definition.Anonymous),
    title: "PricePage",
    template: AnonymousTemplate,
    pathMatch: "/price/\\d+/\\d+",
  },
];

export { paths, Definition, routes as default };
