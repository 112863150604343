import { Combo } from "c4u-web-components";
import React, { createContext, useCallback, useState } from "react";
import { useKbb } from "../hooks";
import { PriceAdvisorRequest, Vehicle } from "../models";
import { Equipment } from "../models/apis/kbb/vehicle-equipment.model";

const VehicleCompareContext = createContext({} as any);

const VehicleCompareProvider = (props: any) => {
  const [vehiclesVCContext, setVehiclesVCContext] = useState<Vehicle[]>([]);
  const [vehiclesAllEquipmentsVCContext, setVehiclesAllEquipmentsVCContext] =
    useState<Equipment[]>([]);
  const [yearsVCContext, setYearsVCContext] = useState<Combo[]>();
  const [brandsVCContext, setBrandsVCContext] = useState<Combo[]>();

  const {
    GetAllEquipments,
    GetAllYears,
    GetAllVehicleBrand,
    GetVehiclePrices,
  } = useKbb();

  const GetAllEquipmentsVCContext = useCallback(async () => {
    if (vehiclesAllEquipmentsVCContext?.length > 0) {
      return vehiclesAllEquipmentsVCContext;
    } else {
      const equips = await GetAllEquipments();
      const allEquips = equips
        ?.map((m) => m.subCategory.map((sb) => sb.equipments))
        ?.flatMap((fm) => fm.flatMap((f) => f));
      setVehiclesAllEquipmentsVCContext(allEquips);
      return allEquips;
    }
  }, [GetAllEquipments, vehiclesAllEquipmentsVCContext]);

  const GetAllYearsComboAsync = useCallback(async () => {
    if (!yearsVCContext?.length) {
      const items = await GetAllYears();

      const itemsCombo = items?.map(
        (m) =>
          new Combo({
            title: m.year,
            value: m.year,
          })
      );
      setYearsVCContext(itemsCombo);

      return itemsCombo;
    }

    return yearsVCContext;
  }, [GetAllYears, yearsVCContext]);

  const GetAllBrandComboAsync = useCallback(async () => {
    if (!brandsVCContext?.length) {
      const items = await GetAllVehicleBrand();

      const itemsCombo = items?.map(
        (m) =>
          new Combo({
            title: m.name,
            value: m.id,
          })
      );
      setBrandsVCContext(itemsCombo);

      return itemsCombo;
    }

    return brandsVCContext;
  }, [GetAllVehicleBrand, brandsVCContext]);

  const GetVehiclePricesVCContext = useCallback(
    async (
      vehicle: Vehicle,
      kms: number | null,
      vehiclePriceTypeId: number,
      stateId: number,
      vehicleGradeId: number,
      equipmentsPrice: number,
      equipmentsColor: number | null,
      equipmentsIds: number[]
    ) => {
      const request = {
        vehicleID: vehicle.id,
        vehiclePriceTypeID: vehiclePriceTypeId,
        mileage: !kms ? vehicle.avMileage : kms,
        year: vehicle.year,
        locationStateID: stateId,
        vehicleGradeID: vehicleGradeId,
      } as PriceAdvisorRequest;

      if (equipmentsPrice > 0) request.equipmentCost = equipmentsPrice;
      if (equipmentsColor) request.colorID = equipmentsColor;
      if (equipmentsIds?.length) request.equipmentsIds = equipmentsIds;

      const price = await GetVehiclePrices(request);
      return price;
    },
    [GetVehiclePrices]
  );

  return (
    <VehicleCompareContext.Provider
      value={{
        vehiclesVCContext,
        setVehiclesVCContext,
        GetAllEquipmentsVCContext,
        GetVehiclePricesVCContext,
        yearsVCContext,
        GetAllYearsComboAsync,
        brandsVCContext,
        GetAllBrandComboAsync,
      }}
    >
      {props.children}
    </VehicleCompareContext.Provider>
  );
};

export { VehicleCompareContext, VehicleCompareProvider };
