import { DropdownDefault, InputDefault } from "c4u-web-components";
import React, { useEffect, useMemo } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import {
  usePriceContext,
  useSearchContext,
  useVehicleCompareContext,
} from "../../../../hooks";
import {
  AdviceKm,
  Img,
  LabelField,
  Opacity,
  SubTitleVehicle,
  TitleVehicle,
} from "./vehicle-sample.molecule.style";

export const VehicleSample = (): JSX.Element => {
  const { t } = useTranslation();

  const { vehicleContext } = useSearchContext();
  const {
    kms,
    setKms,
    vehicleGrades,
    vehicleGradeSelected,
    setVehicleGradeSelected,
    priceTypeSelectedContext,
    kmsVC,
    setKmsVC,
    editEquipmentsCompareContext,
    vehicleCompareCardIndex: vcIndex,
  } = usePriceContext();
  const { vehiclesVCContext } = useVehicleCompareContext();

  const vehicleContextFacade = useMemo(() => {
    return editEquipmentsCompareContext
      ? vehiclesVCContext[vcIndex]
      : vehicleContext;
  }, [
    editEquipmentsCompareContext,
    vcIndex,
    vehicleContext,
    vehiclesVCContext,
  ]);

  const kmsFacade = useMemo(() => {
    return editEquipmentsCompareContext ? kmsVC[vcIndex] : kms;
  }, [editEquipmentsCompareContext, kmsVC, vcIndex, kms]);

  const setKmsFacade = useMemo(() => {
    if (editEquipmentsCompareContext) {
      return (value: string) => {
        const kms_ = [...kmsVC];
        kms_[vcIndex] = value;
        setKmsVC(kms_);
      };
    }
    return setKms;
  }, [editEquipmentsCompareContext, setKms, kmsVC, vcIndex, setKmsVC]);

  useEffect(() => {
    setKmsFacade(kms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeKm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKmsFacade(event.target.value?.replace(/[^\d]/g, ""));
  };

  const priceTypeIsZeroKm = priceTypeSelectedContext?.value === 0;
  const priceTypeIsDealer = priceTypeSelectedContext?.value === 1;

  return (
    <Row>
      <Col md={4}>
        {!!vehicleContextFacade && (
          <Img src={vehicleContextFacade.firstMediaCompleteURLLarge} />
        )}
      </Col>
      {!!vehicleContextFacade && (
        <Col md={8}>
          <Row>
            <Col>
              <TitleVehicle>
                {vehicleContextFacade.brandName}{" "}
                {vehicleContextFacade.modelName}
              </TitleVehicle>
            </Col>
          </Row>
          <Row>
            <Col>
              <SubTitleVehicle>
                {vehicleContextFacade.versionName} {vehicleContextFacade.year}
              </SubTitleVehicle>
            </Col>
          </Row>
          {priceTypeIsZeroKm ? (
            <Opacity fade={priceTypeIsZeroKm}>
              <Row>
                <Col>
                  <LabelField>{t("VehicleConditions")}</LabelField>
                </Col>
                <Col>
                  <LabelField>{t("Mileage")}</LabelField>
                </Col>
              </Row>

              <Row>
                <Col>
                  <DropdownDefault
                    data={vehicleGrades}
                    selectText={t("Select")}
                    value={vehicleGrades[1] ?? vehicleGradeSelected}
                    searchField={false}
                    onChange={setVehicleGradeSelected}
                    disabled
                  />
                </Col>
                <Col>
                  <InputDefault
                    value={priceTypeIsZeroKm ? 0 : kmsFacade}
                    onChange={handleChangeKm}
                    maxLength={8}
                    disabled
                  />
                </Col>
              </Row>
            </Opacity>
          ) : (
            <>
              <Row>
                <Col>
                  <Opacity fade={priceTypeIsDealer}>
                    <LabelField>{t("VehicleConditions")}</LabelField>
                  </Opacity>
                </Col>

                <Col>
                  <LabelField>{t("Mileage")}</LabelField>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Opacity fade={priceTypeIsDealer}>
                    <DropdownDefault
                      data={vehicleGrades}
                      selectText={t("Select")}
                      value={
                        priceTypeIsDealer && !!vehicleGrades[1]
                          ? vehicleGrades[1]
                          : vehicleGradeSelected
                      }
                      searchField={false}
                      onChange={setVehicleGradeSelected}
                      disabled={priceTypeIsDealer}
                    />
                  </Opacity>
                </Col>

                <Col>
                  <InputDefault
                    value={kmsFacade}
                    onChange={handleChangeKm}
                    maxLength={8}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col></Col>
            <Col>
              <AdviceKm>
                {t("MileageFeature").replace(
                  "{0}",
                  priceTypeIsZeroKm
                    ? "0"
                    : vehicleContextFacade.avMileage.toString()
                )}
              </AdviceKm>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};
