import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  AutocompleteGrouped,
  Combo,
  ComboGroup,
  CoxIcon,
  DropdownCustom,
  SortHelper,
} from "c4u-web-components";

import {
  InputGroup,
  Separator,
  Button,
  Wrapper,
  DivDropdown,
  AutoDropdown,
} from "./bar-field-search.molecule.style";
import { useKbb, useSessionContext, useStateStorage } from "../../../../hooks";
import { VehicleBrandModels } from "../../../../models";
import { useAuth0 } from "@auth0/auth0-react";
import { LocalStorageKeys } from "../../../../constants";

export const BarFieldSearch = (): JSX.Element => {
  const { isAuthenticated } = useAuth0();

  const { t } = useTranslation();

  let history = useHistory();

  const {
    globalState,
    vehicleBrandContext,
    anonymousContext,
    setVehicleBrandContext,
  } = useSessionContext();
  const { GetAllLocationState, GetAllVehicleModels } = useKbb();
  const {
    setStateStorage,
    setAllBrandStorage,
    setAllStatesStorage,
    getLocalStorage,
  } = useStateStorage();

  const [vehicleBrandModels, setVehicleBrandModels] = useState<
    VehicleBrandModels[]
  >(() => getLocalStorage(LocalStorageKeys.ALL_VEHICLE_MODELS));

  const [vehicleModelsCombo, setVehicleModelsCombo] = useState<ComboGroup[]>([
    new ComboGroup({
      title: "...",
      value: "",
      group: t("Loading"),
    }),
  ]);

  const [comboGroupVehicleSelected, setComboGroupVehicleSelected] =
    useState<ComboGroup>();
  const [states, setStates] = useState<Combo[]>({} as Combo[]);
  const [state, setState] = useState<Combo>();

  const getStatesAsync = useCallback(async (): Promise<void> => {
    const inStorage = getLocalStorage(LocalStorageKeys.ALL_STATES) as Combo[];

    const storageCombos = inStorage.map((m) => {
      return new Combo({
        value: m.value,
        title: m.title,
      });
    });

    setStates(storageCombos);

    const items = await GetAllLocationState();

    const listSort = items.sort((a, b) => SortHelper.ascProp(a, b, "name"));

    const itemsCombo = listSort?.map(
      (m) =>
        new Combo({
          title: m.name,
          value: m.id,
        })
    );

    setAllStatesStorage(itemsCombo);
    setStates(itemsCombo);
    // eslint-disable-next-line
  }, [GetAllLocationState, getLocalStorage, setAllStatesStorage]);

  const setValueState = useCallback(async (): Promise<void> => {
    if (states?.length > 0 && !state) {
      const stateSelected = states.find((f) =>
        f.title?.toLowerCase().includes(globalState?.toString()?.toLowerCase())
      );
      if (stateSelected) setState(stateSelected);
    }
    // eslint-disable-next-line
  }, [globalState, states]);

  const getAllVehicleModelsAsync = useCallback(async (): Promise<void> => {
    let items = vehicleBrandContext
      ? vehicleBrandContext
      : await GetAllVehicleModels();

    setVehicleBrandContext(items);
    setVehicleBrandModels(items);
    setAllBrandStorage(items);

    // eslint-disable-next-line
  }, [GetAllVehicleModels, t]);

  useEffect(() => {
    const itemsComboBrand = vehicleBrandModels?.map(
      (m) =>
        new ComboGroup({
          value: m.id,
          title: m.name,
          group: t("Brand"),
        })
    );

    const itemsComboModel = vehicleBrandModels?.map((m) => {
      return m.models.map(
        (m) =>
          new ComboGroup({
            value: m.id,
            title: m.name,
            group: t("Vehicle"),
          })
      );
    });

    const itemsComboModelFlat = itemsComboModel.flatMap((f) => f);

    setVehicleModelsCombo(
      [itemsComboBrand, [...itemsComboModelFlat]].flatMap((f) => f)
    );
  }, [vehicleBrandModels, t]);

  useEffect(() => {
    if (isAuthenticated) {
      getStatesAsync();
      getAllVehicleModelsAsync();
    } else {
      if (anonymousContext?.isAnonymous) {
        getStatesAsync();
        getAllVehicleModelsAsync();
      }
    }
  }, [isAuthenticated, anonymousContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValueState();
  }, [globalState, setValueState]);

  const search = () => {
    if (comboGroupVehicleSelected) {
      if (comboGroupVehicleSelected.group === t("Brand")) {
        history.push(
          `/search/${comboGroupVehicleSelected.value}${
            anonymousContext?.anonymousUrlParam ?? ""
          }`
        );
      } else {
        const item = vehicleBrandModels?.find((f) =>
          f.models.find((m) => m.id === comboGroupVehicleSelected.value)
        );
        if (item)
          history.push({
            pathname: `/search/${item.id}${
              anonymousContext?.anonymousUrlParam ?? ""
            }`,
            state: { modelId: comboGroupVehicleSelected.value },
          });
      }
    }
  };

  const handleChange = (comboSelected: ComboGroup) => {
    setComboGroupVehicleSelected(comboSelected);
  };

  useEffect(() => {
    if (state) setStateStorage(state.value.toString());
  }, [state, setStateStorage]);

  return (
    <>
      <Wrapper>
        <DivDropdown className={"d-sm-none mb-2"}>
          {state && (
            <DropdownCustom
              data={states}
              selectText={t("Select")}
              value={state}
              onChange={setState}
              adorn={<CoxIcon name="marker" />}
            />
          )}
        </DivDropdown>
        <InputGroup>
          <DropdownCustom
            className={"d-none d-sm-block"}
            data={states}
            selectText={t("Select")}
            value={state}
            onChange={setState}
            adorn={<CoxIcon name="marker" />}
          />
          <Separator className={"d-none d-sm-block"} />
          <AutoDropdown>
            <AutocompleteGrouped
              id={"vehiclesAllModels"}
              placeholder={t("Search by brand or model") + "..."}
              options={vehicleModelsCombo}
              minChars={2}
              onChange={handleChange}
              noMatchesFoundText={t("No matches found")}
            />
          </AutoDropdown>

          <InputGroup.Append>
            <Button variant="link" onClick={search}>
              <CoxIcon name="search" />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Wrapper>
    </>
  );
};
