import styled from "styled-components";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/esm/Button";
import { darken } from "polished";

interface Props {
  hidden?: boolean;
}

const DivBoxVehicleFeature = styled.div`
  padding: 75px 0 54px;
  max-width: 384px;
`;

const Wrapper = styled.div<Props>`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: ${(props) => (props.hidden ? "none" : "inherit")};
`;

const Description = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 14px;
  color: ${(props) => props.theme.colors.black};
  line-height: 24px;
`;

const RowFooter = styled(Row)`
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  height: 44px;
  width: 156px;
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.BlueNavy};
  text-align: center;
  :hover {
    color: ${(props) => darken(0.1, props.theme.colors.BlueNavy)};
    text-decoration: none;
  }
`;

export {
  DivBoxVehicleFeature,
  Wrapper,
  Description,
  RowFooter,
  ButtonStyled as Button,
};
