import { useContext } from 'react';
import { SearchContext } from '../../contexts';
import { Vehicle, VehicleSearch, VehicleBrand } from '../../models';

interface SearchProviderState {
  vehicleContext: Vehicle;
  setVehicleContext: React.Dispatch<React.SetStateAction<Vehicle | null>>;
  vehiclesCompareContext: Vehicle[];
  setVehiclesCompareContext: React.Dispatch<React.SetStateAction<Vehicle[]>>;
  vehiclesSearchedContext: VehicleSearch[];
  setVehiclesSearchedContext: React.Dispatch<VehicleSearch[]>;
  vehicleBrandContext: VehicleBrand;
  setVehicleBrandContext: React.Dispatch<React.SetStateAction<VehicleBrand | null>>;
}

export const useSearchContext = () => {
  const context = useContext<SearchProviderState>(SearchContext);

  if (!context) {
    throw new Error('Empty context');
  }

  return context;
};
