import styled from 'styled-components';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Wrapper = styled.div`
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.WhiteSmoke};
    border-radius: 10px;
    min-height: 230px;
    padding: 40px 10px;
    margin: 32px auto 0;
`;

const RowStyled = styled(Row)`
`;

const ColStyled = styled(Col)`
    text-align: center;
`;


export { Wrapper, RowStyled, ColStyled as Col }