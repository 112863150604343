export class SortHelper {

    static asc(a: string | number, b: string | number) {
        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        }
        return 0;
    }

    static desc(a: string | number, b: string | number) {
        if (a > b) {
            return -1;
        } else if (a < b) {
            return 1;
        }
        return 0;
    }

    static ascProp(a: any, b: any, key: string) {
        if (a[key] > b[key]) {
            return 1;
        } else if (a[key] < b[key]) {
            return -1;
        }
        return 0;
    }

    static descProp(a: any, b: any, key: string) {
        if (a[key] > b[key]) {
            return -1;
        } else if (a[key] < b[key]) {
            return 1;
        }
        return 0;
    }
}