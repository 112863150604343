import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Autocomplete, Combo, LabelDefault } from "c4u-web-components";

import {
  Col,
  Row,
  DivText,
  DivClearFilters,
} from "./vehicle-options.molecule.style";

import { useKbb, useSearchContext } from "../../../../hooks";
import { VehicleModel, Years, VehicleBrand } from "../../../../models";
import { YearSelector } from "../..";
import { SortHelper } from "../../../../helper";

export const VehicleOptions = (): JSX.Element => {
  const { t } = useTranslation();

  const location = useLocation();
  const params = useParams();

  const { setVehicleContext, setVehicleBrandContext } = useSearchContext();

  const {
    GetAllVehicleBrand,
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetVehicle,
  } = useKbb();

  const comboShortLoading = new Combo({ title: "...", value: "" });

  const [vehicleBrandCombo, setVehicleBrandCombo] = useState<Combo[]>();
  const [vehicleModelCombo, setVehicleModelCombo] = useState<Combo[]>();
  const [vehicleVersionCombo, setVehicleVersionCombo] = useState<Combo[]>();
  const [years, setYears] = useState<Combo[]>([comboShortLoading]);

  const [vehicleBrandSelected, setVehicleBrandSelected] = useState<Combo>();
  const [vehicleYearSelected, setVehicleYearSelected] = useState<Combo>();
  const [vehicleModelSelected, setVehicleModelSelected] = useState<Combo>();
  const [vehicleVersionSelected, setVehicleVersionSelected] = useState<Combo>();

  const [vehicleYears, setVehicleYears] = useState<Years[]>();
  const [vehicleModels, setVehicleModels] = useState<VehicleModel[]>();

  const [loadedModelParam, setLoadedModelParam] = useState(false);

  const brandIdParam = useMemo(() => {
    return (params as any).brandId;
  }, [params]);

  const modelIdParam = useMemo(() => {
    return (location.state as any)?.modelId;
  }, [location]);

  const getVehiclesBrandAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllVehicleBrand();
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.name,
          value: m.id,
        })
    );
    setVehicleBrandCombo(itemsCombo);
  }, [GetAllVehicleBrand]);

  const getVehicleVersionAsync = useCallback(
    async (modelId: number, year: number): Promise<void> => {
      if (modelId && year) {
        const items = await GetAllVehicleVersion(modelId, year);
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: m.kbbid,
            })
        );
        setVehicleVersionCombo(itemsCombo);
      }
    },
    [GetAllVehicleVersion]
  );

  const getVehiclesModelAsync = useCallback(
    async (brandId: number, year: number | null = null): Promise<void> => {
      if (brandId && (year || modelIdParam)) {
        const items = await GetAllVehicleModelByBrand(brandId, year);
        setVehicleModels(items);
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: m.id,
            })
        );
        setVehicleModelCombo(itemsCombo);

        if (vehicleModelSelected && year) {
          const modelExists = itemsCombo.find(
            (f) => f.value === vehicleModelSelected?.value
          );
          if (modelExists) {
            getVehicleVersionAsync(Number(modelExists.value), year);
          } else {
            setVehicleModelSelected(undefined);
          }
        }
      }
    },
    [
      GetAllVehicleModelByBrand,
      modelIdParam,
      getVehicleVersionAsync,
      vehicleModelSelected,
    ]
  );

  const getVehicleAsync = useCallback(
    async (vehicleId: number): Promise<void> => {
      if (vehicleId) {
        const items = await GetVehicle(vehicleId);
        setVehicleContext(items);
      }
    },
    [GetVehicle, setVehicleContext]
  );

  const GetAllYearsAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllYears();
    setVehicleYears(items);
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.year,
          value: m.year,
        })
    );
    setYears(itemsCombo);
  }, [GetAllYears]);

  const handleChangeBrand = (comboValue: Combo) => {
    const value = Number(comboValue?.value);
    setVehicleBrandSelected(comboValue);
    setVehicleContext(null);
    setVehicleBrandContext(null);
    if (vehicleYearSelected)
      getVehiclesModelAsync(value, Number(vehicleYearSelected.value));
    setVehicleModelSelected(undefined);
    setVehicleVersionSelected(undefined);
  };
  const handleChangeYear = (comboValue: Combo) => {
    const value = Number(comboValue?.value);
    setVehicleYearSelected(comboValue);
    setVehicleContext(null);
    if (vehicleBrandSelected)
      getVehiclesModelAsync(Number(vehicleBrandSelected.value), value);
    //setVehicleModelSelected(undefined);
    setVehicleVersionSelected(undefined);
  };
  const handleChangeVersion = (comboValue: Combo) => {
    if (comboValue?.value) getVehicleAsync(Number(comboValue.value));
    else setVehicleContext(null);

    setVehicleVersionSelected(comboValue);
  };
  const handleChangeModel = useCallback(
    (comboValue: Combo) => {
      setVehicleModelSelected(comboValue);
      const value = Number(comboValue?.value);
      setVehicleContext(null);
      if (vehicleYearSelected)
        getVehicleVersionAsync(value, Number(vehicleYearSelected.value));
      setVehicleVersionSelected(undefined);
    },
    [vehicleYearSelected, setVehicleContext, getVehicleVersionAsync]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const cleanFilters = () => {
    setVehicleBrandSelected(undefined);
    setVehicleYearSelected(undefined);
    setVehicleBrandContext(null);
    setVehicleContext(null);
  };

  const loadBrandsParamAsync = useCallback(
    (brandsCombo: Combo[]) => {
      const brandID = Number(brandIdParam);
      const comboBrand = brandsCombo.find((f) => f.value === brandID);
      if (comboBrand) {
        setVehicleBrandSelected(comboBrand);
        getVehiclesModelAsync(Number(comboBrand.value));
      } else {
        setVehicleBrandContext(null);
      }
    },
    // eslint-disable-next-line
    [brandIdParam, getVehiclesModelAsync]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const loadVehicleBrand = useCallback(
    (brandsCombo: VehicleBrand) => {
      setVehicleBrandContext(brandsCombo);
    },
    [setVehicleBrandContext]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const loadModelsParamAsync = useCallback(
    (brandsCombo: Combo[]) => {
      if (!loadedModelParam) {
        const comboModel = brandsCombo.find(
          (f) => f.value === Number(modelIdParam)
        );

        if (!vehicleYearSelected) {
          if (comboModel) {
            handleChangeModel(comboModel);
            const yearsModel = vehicleModels?.find(
              (f) => f.id === Number(modelIdParam)
            );

            const comboYears = years.find(
              (f) => f.value === yearsModel?.years[yearsModel?.years.length - 1]
            );
            setVehicleYearSelected(comboYears);
          }
        } else if (comboModel) {
          setLoadedModelParam(true);
          handleChangeModel(comboModel);
        }
      }
    },
    [
      vehicleModels,
      years,
      handleChangeModel,
      modelIdParam,
      vehicleYearSelected,
      loadedModelParam,
    ]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleBrandCombo) loadBrandsParamAsync(vehicleBrandCombo);
  }, [vehicleBrandCombo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleModelCombo) loadModelsParamAsync(vehicleModelCombo);
  }, [vehicleModelCombo, vehicleYearSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleModelSelected && vehicleModels) {
      //set years of models
      const modelSelected = vehicleModels.find(
        (f) => f.id === vehicleModelSelected.value
      );
      const itemsComboYears = modelSelected?.years.sort(SortHelper.desc).map(
        (y) =>
          new Combo({
            title: y,
            value: y,
          })
      );
      if (itemsComboYears) setYears(itemsComboYears);
    } else {
      const itemsCombo = vehicleYears?.map(
        (m) =>
          new Combo({
            title: m.year,
            value: m.year,
          })
      );

      if (itemsCombo) setYears(itemsCombo);
    }
  }, [vehicleModelSelected, vehicleModels, vehicleYears]);

  useEffect(() => {
    getVehiclesBrandAsync();
    GetAllYearsAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleBrandSelected) {
      const vehicleBrandContext = {
        id: Number(vehicleBrandSelected.value),
        name: vehicleBrandSelected.title,
      };

      if (vehicleBrandContext) loadVehicleBrand(vehicleBrandContext);
    }
  }, [vehicleBrandSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row>
        <Col>
          <DivText>
            <LabelDefault>{t("Brand")}</LabelDefault>
            <Autocomplete
              id={"vehiclesBrand"}
              placeholder={t("Brand") + "..."}
              options={vehicleBrandCombo}
              onChange={handleChangeBrand}
              value={vehicleBrandSelected}
              loadingText={t("Loading")}
              noMatchesFoundText={t("No matches found")}
            />
          </DivText>
        </Col>
      </Row>
      <Row>
        <Col>
          <YearSelector
            years={years}
            value={vehicleYearSelected}
            onChange={handleChangeYear}
          />
        </Col>
      </Row>
      {vehicleBrandSelected && vehicleYearSelected && (
        <>
          <Row>
            <Col>
              <DivText>
                <LabelDefault>{t("Model")}</LabelDefault>
                <Autocomplete
                  id={"vehiclesModel"}
                  placeholder={t("Model") + "..."}
                  options={vehicleModelCombo}
                  onChange={handleChangeModel}
                  value={vehicleModelSelected}
                />
              </DivText>
            </Col>
          </Row>
        </>
      )}
      {vehicleBrandSelected && vehicleYearSelected && vehicleModelSelected && (
        <>
          <Row>
            <Col>
              <DivText>
                <LabelDefault>{t("TypeItVersion")}</LabelDefault>
                <Autocomplete
                  id={"vehicleVersion"}
                  placeholder={t("Version") + "..."}
                  options={vehicleVersionCombo}
                  onChange={handleChangeVersion}
                  value={vehicleVersionSelected}
                />
              </DivText>
            </Col>
          </Row>
        </>
      )}
      {(vehicleBrandSelected || vehicleYearSelected) && (
        <Row>
          <Col>
            <DivClearFilters onClick={cleanFilters}>
              {t("Clean Filters")}
            </DivClearFilters>
          </Col>
        </Row>
      )}
    </>
  );
};
