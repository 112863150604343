import { useAuth0 } from "@auth0/auth0-react";
import { ButtonSecondary, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LocalStorageKeys } from "../../../../constants";
import {
  useSessionContext,
  useStateStorage,
  useVehicleSearch,
} from "../../../../hooks";
import { VehicleSearch } from "../../../../models";
import { BoxVehicleDetail } from "../../../molecules";
import { ColButtonMore, Wrapper } from "./latest-searches.organism.style";

export const LatestSearches = () => {
  const { isAuthenticated } = useAuth0();

  const { vehicleSearchContext, setVehicleSearchContext } = useSessionContext();
  const { getStateStorage, setAllHistoryStorage, getLocalStorage } =
    useStateStorage();
  const { GetVehicleSearch } = useVehicleSearch();

  const { t } = useTranslation();

  const [state, setState] = useState<number>();
  const [lastestSearches] = useState<VehicleSearch[]>(() => {
    if (LocalStorageKeys.LASTEST_SEARCHES in localStorage)
      return getLocalStorage(LocalStorageKeys.LASTEST_SEARCHES);
    else return undefined;
  });

  const setStateAsync = useCallback(async () => {
    setState(await getStateStorage());
  }, [getStateStorage]);

  const getAllVehicleSearchesAsync = useCallback(async (): Promise<void> => {
    setVehicleSearchContext(lastestSearches);
    const itemsPage = await GetVehicleSearch(5, 0, 0);
    setVehicleSearchContext(itemsPage.data.filter((f) => f.vehicle));
    if (itemsPage) {
      setAllHistoryStorage(itemsPage.data.filter((f) => f.vehicle));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetVehicleSearch, setVehicleSearchContext, setAllHistoryStorage]);

  useEffect(() => {
    if (isAuthenticated) {
      setStateAsync();
      getAllVehicleSearchesAsync();
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const RenderCol = (stateItem: number) => {
    if (vehicleSearchContext?.length)
      return vehicleSearchContext.map((m, i) => (
        <Col key={i} sm={"auto"}>
          {m && (
            <BoxVehicleDetail
              vehicleSearched={m}
              editble={false}
              key={`box-${i}`}
              stateID={stateItem}
              vehiclePriceTypeID={m.vehiclePriceTypeId}
            />
          )}
        </Col>
      ));

    return [];
  };

  return (
    <Wrapper>
      {vehicleSearchContext === undefined ? (
        <SpinnerCenteredAtom />
      ) : (
        <>
          {!vehicleSearchContext?.length ? (
            <Row>
              <Col className={"text-center m-2"}>{t("No searches found")}</Col>
            </Row>
          ) : (
            <Row>{state && RenderCol(state)}</Row>
          )}
          {!!vehicleSearchContext?.length && (
            <Row>
              <ColButtonMore>
                <Link to={"/search-history"}>
                  <ButtonSecondary sizex={"lg"}>
                    {t("History searches")}
                  </ButtonSecondary>
                </Link>
              </ColButtonMore>
            </Row>
          )}
        </>
      )}
    </Wrapper>
  );
};
