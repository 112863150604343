import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const DivTitleCategory = styled.div`
    height: 48px;
    background-color: ${props => props.theme.colors.COXWhite};
    border: 1px solid ${props => props.theme.colors.DarkGrayOpacity};
    box-shadow: 0px 4px 12px 0px ${props => props.theme.colors.BlueHighlight};
    position: relative;

    font-family: ${props => props.theme.fontFamily.OpenSans};
    font-weight: bold;
    color: ${props => props.theme.colors.Dark};
    line-height: 22px;
    padding: 12px;
`;

const DivContent = styled.div`
    min-height: 48px;
    padding: 12px;
    background-color: ${props => props.theme.colors.Background};
    border: 1px solid ${props => props.theme.colors.DarkGrayOpacity};
    color: ${props => props.theme.colors.Dark};
    font-family: ${props => props.theme.fontFamily.OpenSans};
    font-size: 12px;
    label {
      color: ${props => props.theme.colors.Dark} !important;
    }
`;

const TextSelectOptions = styled.div`
  font-family: ${props => props.theme.fontFamily.Montserrat};
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.Dark};
  line-height: 28px;
`;

const LabelTypeEquipment = styled.div`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.BlueNavy};
  margin: 0 14px 0 0;
`;

export {
  Wrapper,
  DivTitleCategory,
  DivContent,
  TextSelectOptions,
  LabelTypeEquipment
}