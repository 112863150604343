import { useAuth0 } from "@auth0/auth0-react";
import {
  ButtonSecondary,
  Combo,
  DropdownDefault,
  LabelDefault,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import {
  useKbb,
  useSessionContext,
  useStateStorage,
  useVehicleSearch,
} from "../../../../hooks";
import { VehicleSearch } from "../../../../models";
import { BoxVehicleDetail } from "../../../molecules";
import { Hr } from "./search-history.organism.style";

export const SearchHistory = (): JSX.Element => {
  const { t } = useTranslation();

  const { isAuthenticated } = useAuth0();

  const { getStateStorage } = useStateStorage();
  const { GetAllLocationState } = useKbb();
  const { GetVehicleSearch } = useVehicleSearch();

  const [states, setStates] = useState<Combo[]>([]);
  const [stateSelected, setStateSelected] = useState<Combo>();
  const [orderSelected, setOrderSelected] = useState<Combo>();
  const [vehicleLocalPriceTypeSelected, setVehicleLocalPriceTypeSelected] =
    useState<Combo>();
  const [vehiclesSearchedLocal, setVehiclesSearchedLocal] =
    useState<VehicleSearch[]>();
  const { priceTypeComboContext } = useSessionContext();
  const [totalRecords, setTotalRecords] = useState<number>(99);

  const getVehicleSearch = useCallback(
    async (newVehiclesSearchedLocal: VehicleSearch[]) => {
      var response = await GetVehicleSearch(
        10,
        newVehiclesSearchedLocal.length,
        orderSelected?.value
      );
      response.data.forEach((vehicleSearch) => {
        newVehiclesSearchedLocal.push(vehicleSearch);
      });
      setVehiclesSearchedLocal(newVehiclesSearchedLocal);
      setTotalRecords(response.totalRecords);
    },
    [GetVehicleSearch, orderSelected]
  );

  const SeeMoreClick = useCallback(() => {
    if (vehiclesSearchedLocal === undefined) getVehicleSearch([]);
    else getVehicleSearch([...vehiclesSearchedLocal]);
  }, [getVehicleSearch, vehiclesSearchedLocal]);

  useEffect(() => {
    if (isAuthenticated) {
      getVehicleSearch([]);
    }
  }, [orderSelected, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStateAsync = useCallback(
    async (statesCombo: Combo[]) => {
      const state = await getStateStorage();
      const stateDefault = statesCombo.find((f) => f.value === state);
      setStateSelected(stateDefault);
    },
    [getStateStorage]
  );

  const getStatesAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllLocationState();
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.name,
          value: m.id,
        })
    );
    setStates(itemsCombo);

    getStateAsync(itemsCombo);
  }, [GetAllLocationState, getStateAsync]);

  const order = useMemo(() => {
    return [
      { title: t("Date"), value: 0 },
      { title: t("Price"), value: 1 },
    ] as Combo[];
  }, [t]);

  const vehiclesSearchedExcluded = useCallback(
    async (vehicleSearchId: number) => {
      let newVehiclesSearchedLocal =
        vehiclesSearchedLocal === undefined ? [] : [...vehiclesSearchedLocal];
      var excluido = newVehiclesSearchedLocal.findIndex(
        (s) => s.id === vehicleSearchId
      );
      newVehiclesSearchedLocal.splice(excluido, 1);
      await getVehicleSearch(newVehiclesSearchedLocal);
    },
    [getVehicleSearch, vehiclesSearchedLocal]
  );

  useEffect(() => {
    getStatesAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row>
        <Col md={3} className={"mb-2"}>
          <LabelDefault>{t("Price Category")}</LabelDefault>
          <DropdownDefault
            data={priceTypeComboContext}
            selectText={t("Select")}
            value={vehicleLocalPriceTypeSelected}
            searchField={false}
            onChange={setVehicleLocalPriceTypeSelected}
          />
        </Col>
        <Col md={3} className={"mb-2"}>
          <LabelDefault>{t("Location")}</LabelDefault>
          <DropdownDefault
            data={states}
            selectText={t("Select")}
            value={stateSelected}
            searchField={true}
            onChange={setStateSelected}
          />
        </Col>
        <Col md={3} className={"mb-2"}></Col>
        <Col md={3} className={"mb-2"}>
          <LabelDefault>{t("Order by")}</LabelDefault>
          <DropdownDefault
            data={order}
            selectText={t("Select")}
            value={orderSelected}
            searchField={false}
            onChange={setOrderSelected}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Hr />
        </Col>
      </Row>
      <Row>
        {stateSelected &&
          vehiclesSearchedLocal?.map((vehiclesSearched, i) => (
            <Col key={i} md={"auto"}>
              <BoxVehicleDetail
                vehicleSearched={vehiclesSearched}
                key={`box-${i}`}
                editble={true}
                stateID={Number(stateSelected.value)}
                vehiclePriceTypeID={
                  vehicleLocalPriceTypeSelected
                    ? Number(vehicleLocalPriceTypeSelected.value)
                    : undefined
                }
                onExclusion={vehiclesSearchedExcluded}
              />
            </Col>
          ))}
        {vehiclesSearchedLocal === undefined ? (
          <Col className={"text-center m-2"}>
            <SpinnerCenteredAtom />
          </Col>
        ) : (
          !vehiclesSearchedLocal?.length && (
            <Col className={"text-center m-2"}>{t("No searches found")}</Col>
          )
        )}
      </Row>
      {!!vehiclesSearchedLocal?.length &&
        vehiclesSearchedLocal.length < totalRecords && (
          <Row>
            <Col className={"text-center"}>
              <ButtonSecondary sizex={"md"} onClick={SeeMoreClick}>
                {t("See more")}
              </ButtonSecondary>
            </Col>
          </Row>
        )}
    </>
  );
};
