import styled from "styled-components";
import Row from "react-bootstrap/esm/Row";

const RowBoxPrice = styled(Row)`
  background-color: ${(props) => props.theme.colors.Background};
  border: 1px solid ${(props) => props.theme.colors.DarkGray};
  border-radius: 4px;
  margin: 0;
  min-height: 66px;
`;

const BoxPrice = styled.div`
  display: inline-flex;
  height: 64px;
`;

const BoxPriceImg = styled.div`
  margin: 0 0 0 -15px;
`;

const BoxPriceInfo = styled.div`
  margin: 14px 0 0 2px;
`;

const BoxPriceInfoTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 14px;
`;
const BoxPriceInfoPrice = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
  line-height: 26px;
`;

const EmptyResult = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  text-align: center;
  line-height: 22px;
`;

export {
  BoxPrice,
  BoxPriceImg,
  BoxPriceInfo,
  BoxPriceInfoTitle,
  BoxPriceInfoPrice,
  RowBoxPrice,
  EmptyResult,
};
