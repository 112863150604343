import React from 'react';
import { useHistory } from 'react-router-dom';

import { GaHelper } from 'c4u-web-components';

export interface GaWrapperProps {
  children: any,
}

export const GaWrapperHelper = (props :GaWrapperProps) => {

  const history = useHistory();

  return (
    <div>
      <GaHelper history={history}>
        { props.children }
      </GaHelper>
    </div>
  );
}
