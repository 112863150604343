import styled, { css } from 'styled-components';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

interface IProps {
    selected: boolean;
}

const DivDropdownStyled = styled.div<IProps>`
    height: 24px;
    width: 72px;
    border: 1px solid ${props => props.theme.colors.DarkGray};
    border-radius: 4px;
    ${({ selected }) => selected ? css`
        background-color: ${props => props.theme.colors.LapisLazul};
    ` : css`
        background-color: ${props => props.theme.colors.GhostWhite};
    `}
    cursor: pointer;
    .buttonDropdown  {
        font-family: 'Open Sans';
        font-size: 12px;
        
        ${({ selected }) => selected ? css`
        color: ${props => props.theme.colors.GhostWhite};
    ` : css`
        color: ${props => props.theme.colors.LapisLazul};
    `}
        line-height: 25px;
    }
    label {
        width: 45px;
        margin: 0 0 0 5px;
        cursor: pointer;
        font-size: 12px;
    }
`;

const ColStyled = styled(Col)`
    margin: 0;
    padding: 0;
`;

const RowStyled = styled(Row)`
    margin: 0;
    padding: 0;
`;

const DivText = styled.div`
    margin: 32px 0 12px 0;
`;

export {
    DivDropdownStyled,
    ColStyled as Col,
    RowStyled as Row,
    DivText
}
