import React from "react";
import { Auth0Provider, } from "@auth0/auth0-react";
import { MainAuth } from "./main.template.auth"

export const MainTemplate = (props: any) => {

  return (
    <>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
        scope={process.env.REACT_APP_AUTH0_SCOPE ?? ""}
        redirectUri={`${window.location.origin}`}
      >

        <MainAuth>
          {props.children}
        </MainAuth>
      </Auth0Provider>
    </>
  );
};
