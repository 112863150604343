import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useKbbAnonymous, useSessionContext } from "../../../hooks";

export const AnonymousTemplate = (props: any) => {
  const history = useHistory();
  const { getUrlAnonymous } = useKbbAnonymous();
  const { setAnonymousContext, anonymousContext, setToken } =
    useSessionContext();

  const [tokenIsSet, setTokenIsSet] = useState(false);

  const allowedOrigins = useMemo(
    () => [
      "http://localhost:3006",
      "https://hml.kbb.coxpravoce.com.br",
      "https://kbb.coxpravoce.com.br",
    ],
    []
  );

  useEffect(() => {
    setAnonymousContext({
      isAnonymous: false,
      anonymousUrlParam: "",
    });
    setToken("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTokenAsync = useCallback(
    async (queryString: string) => {
      setTokenIsSet(false);
      let params = new URLSearchParams(queryString);
      const plugin = params.get("plugin");
      let token = params.get("token");

      if (
        !token &&
        !!plugin &&
        ["4Rodas", "FenabraveSC"].includes(plugin) &&
        allowedOrigins.includes(window.location.origin)
      ) {
        const { url } = await getUrlAnonymous({ plugin });
        let newQueryString = url.split("?")[1];
        params = new URLSearchParams(newQueryString);
        token = params.get("token");
      }

      if (token) {
        setAnonymousContext({
          plugin: plugin ?? "",
          isAnonymous: true,
          anonymousUrlParam: token
            ? `/anonymous?token=${token}`
            : plugin
            ? `/anonymous?plugin=${plugin}`
            : "/anonymous",
        });
        setToken(token);
        setTokenIsSet(true);
      }
    },
    [allowedOrigins, getUrlAnonymous, setAnonymousContext, setToken]
  );

  useEffect(() => {
    if (history && history.location.pathname.includes("anonymous")) {
      setTokenAsync(history.location.search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      {anonymousContext?.isAnonymous && tokenIsSet && (
        <Container fluid>{props.children}</Container>
      )}
    </>
  );
};
