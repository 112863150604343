import { Combo, LinkGroupItem } from "c4u-web-components";
import React, { createContext, useState } from "react";
import { PriceAdvisorRequest, VehiclePriceTypeIdEnum } from "../models";

const PriceContext = createContext({} as any);

const PriceProvider = (props: any) => {
  const [
    openModalEquipamentsOptionsContext,
    setOpenModalEquipamentsOptionsContext,
  ] = useState<boolean>(false);
  const [openModalCompareContext, setOpenModalCompareContext] =
    useState<boolean>(false);
  const [editEquipmentsCompareContext, setEditEquipmentsCompareContext] =
    useState<boolean>(false);
  const [kms, setKms] = useState<string>();
  const [vehicleGrades, setVehicleGrades] = useState<Combo[]>([
    new Combo({ title: "...", value: "" }),
  ]);
  const [vehicleGradeSelected, setVehicleGradeSelected] = useState<Combo>();
  const [continueProcess, setContinueProcess] = useState<boolean>();
  const [equipmentsPrice, setEquipmentsPrice] = useState<number>(0);
  const [equipmentsColor, setEquipmentsColor] = useState<number>();
  const [equipmentsColorTag, setEquipmentsColorTag] = useState<number>();
  const [equipmentsIds, setEquipmentsIds] = useState<number[]>([]);
  const [requestPrice, setRequestPrice] = useState<PriceAdvisorRequest>();
  const [priceTypeSelectedContext, setPriceTypeSelectedContext] =
    useState<LinkGroupItem>();
  const [vehiclePriceTypeIdContext, setVehiclePriceTypeIdContext] =
    useState<VehiclePriceTypeIdEnum>(VehiclePriceTypeIdEnum.Dealer);

  const [kmsVC, setKmsVC] = useState<string[]>([]);
  const [equipmentsPriceVC, setEquipmentsPriceVC] = useState<number[]>([]);
  const [equipmentsColorVC, setEquipmentsColorVC] = useState<number[]>([]);
  const [equipmentsColorTagVC, setEquipmentsColorTagVC] = useState<number[]>(
    []
  );
  const [equipmentsIdsVC, setEquipmentsIdsVC] = useState<number[][]>([[]]);
  const [vehicleCompareCardIndex, setVehicleCompareCardIndex] = useState<
    0 | 1 | 2
  >(0);

  return (
    <PriceContext.Provider
      value={{
        openModalEquipamentsOptionsContext,
        setOpenModalEquipamentsOptionsContext,
        kms,
        setKms,
        vehicleGrades,
        setVehicleGrades,
        vehicleGradeSelected,
        setVehicleGradeSelected,
        continueProcess,
        setContinueProcess,
        equipmentsPrice,
        setEquipmentsPrice,
        equipmentsColor,
        setEquipmentsColor,
        equipmentsColorTag,
        setEquipmentsColorTag,
        equipmentsIds,
        setEquipmentsIds,
        openModalCompareContext,
        setOpenModalCompareContext,
        priceTypeSelectedContext,
        setPriceTypeSelectedContext,
        editEquipmentsCompareContext,
        setEditEquipmentsCompareContext,
        requestPrice,
        setRequestPrice,
        vehiclePriceTypeIdContext,
        setVehiclePriceTypeIdContext,
        kmsVC,
        setKmsVC,
        equipmentsPriceVC,
        setEquipmentsPriceVC,
        equipmentsColorVC,
        setEquipmentsColorVC,
        equipmentsColorTagVC,
        setEquipmentsColorTagVC,
        equipmentsIdsVC,
        setEquipmentsIdsVC,
        vehicleCompareCardIndex,
        setVehicleCompareCardIndex,
      }}
    >
      {props.children}
    </PriceContext.Provider>
  );
};

export { PriceContext, PriceProvider };
