import React from "react";
import { useTranslation } from "react-i18next";
import { H2, H3 } from "c4u-web-components";

import { SearchHistory } from "../../organisms";
import { Wrapper } from "./history.page.style";

export const HistoryPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <H2>{t("SearchHistoryTitle")}</H2>
      <H3>{t("SearchHistorySubTitle")}</H3>
      <Wrapper>
        <SearchHistory />
      </Wrapper>
    </>
  );
};
