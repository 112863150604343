import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { darken } from 'polished';

const ColStyled = styled(Col)`
`;
const RowStyled = styled(Row)`
`;

const DivText = styled.div`
    margin: 32px 0 12px 0;
`;

const DivClearFilters = styled.div`
  width: 100%;
  text-align: right;
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.DarkGray};
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;
  margin: 40px 0 0 0;

  :hover {
    color: ${props => darken(0.1, props.theme.colors.DarkGray)};
}

text-transform: uppercase;
`;

export {
  ColStyled as Col,
  RowStyled as Row,
  DivText,
  DivClearFilters
}