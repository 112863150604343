import { Combo } from "c4u-web-components";
import React, { createContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AnonymousModel,
  RouterParam,
  VehicleBrandModels,
  VehicleSearch,
} from "../models";

const SessionContext = createContext({} as any);

const SessionProvider = (props: any) => {
  const { t } = useTranslation();

  const [vehicleSearchContext, setVehicleSearchContext] =
    useState<VehicleSearch[]>();
  const [vehicleBrandContext, setVehicleBrandContext] =
    useState<VehicleBrandModels[]>();
  const [routerParamContext, setRouterParamContext] = useState<RouterParam[]>(
    []
  );
  const [token, setToken] = useState<string>();
  const [globalState, setGlobalSate] = useState<string>();
  const [anonymousContext, setAnonymousContext] = useState<AnonymousModel>();

  const addRouterParamContext = (keyParam: string, value: string) => {
    const key = keyParam.startsWith(":") ? keyParam : `:${keyParam}`;
    const itemsRouterParamContext = routerParamContext.filter(
      (f) => f.param.trim() !== key.trim()
    );
    itemsRouterParamContext.push({ param: key, value: value } as RouterParam);
    setRouterParamContext(itemsRouterParamContext);
  };

  const priceTypeComboContext = useMemo(() => {
    return [
      { title: t("Zero KM Price"), value: 0 },
      { title: t("Dealer Price"), value: 1 },
      // { title: t('Ads Price'), value: 4 },
      { title: t("Private Price"), value: 2 },
      { title: t("Trade-In Price"), value: 3 },
    ] as Combo[];
  }, [t]);

  return (
    <SessionContext.Provider
      value={{
        routerParamContext,
        addRouterParamContext,
        globalState,
        setGlobalSate,
        priceTypeComboContext,
        vehicleBrandContext,
        setVehicleBrandContext,
        vehicleSearchContext,
        setVehicleSearchContext,
        anonymousContext,
        setAnonymousContext,
        token,
        setToken,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
