import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col'
import { darken } from 'polished';

const Wrapper = styled.div`
`;

const ButtonStyled = styled(Button)`
    color: ${({ theme }) => theme.colors.ButtonPrimary.Secondary};

    background: ${({ theme }) => theme.colors.ButtonPrimary.Main};
    border-radius: 0px 4px 4px 0px;

    :hover {
        background-color: ${props => darken(0.1, props.theme.colors.ButtonPrimary.Main)};
        text-decoration: none;
    }

    border-radius: 20px;
    margin: 20px;
    width: 200px;
    font-weight: bold;
`;

const ColButtonMore = styled(Col)`
text-align: right;
`;

export { Wrapper, ButtonStyled as Button, ColButtonMore }