import styled from 'styled-components';
import Card from 'react-bootstrap/esm/Card';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

const EmptyResult = styled.div`
font-family: ${props => props.theme.fontFamily.OpenSans};
font-size: 14px;
font-weight: bold;
color: ${props => props.theme.colors.Dark};
text-align: center;
line-height: 22px;
`;


const CardLineAccordion = styled(Card)`
  min-height: 48px;
  margin: 8px 0 0 0;
`;

const RowLineAccordion = styled(Row)`
  min-height: 48px;
  margin: 0;
`;

const LineAccordionTitle = styled.div`
  background-color: ${props => props.theme.colors.DarkGray};
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 -15px;
`;

const LineAccordionTitleText = styled.div`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.Dark};
  text-align: center;
  line-height: 20px;
`;

const LineAccordionInfo = styled.div`
font-family: ${props => props.theme.fontFamily.Montserrat};
font-size: 14px;
font-weight: bold;
color: ${props => props.theme.colors.Dark};
text-align: center;
line-height: 22px;
`;

const ColContentInfo = styled(Col)`
border-right: 2px solid #d4d8dd;
margin: auto;
:last-child {
  border: none;
}
`;

export {
  EmptyResult, CardLineAccordion, RowLineAccordion, LineAccordionTitleText, LineAccordionInfo, LineAccordionTitle, ColContentInfo
}