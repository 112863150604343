import { CoxIcon, Img } from "c4u-web-components";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../../constants";
import { DateHelper, NumberHelper } from "../../../../helper";
import { useKbb, usePriceContext, useVehicleSearch } from "../../../../hooks";
import {
  PriceAdvisorRequest,
  Vehicle,
  VehicleSearch,
} from "../../../../models";
import { KbbLogoS1Img, KbbLogoS2Img } from "../../../assets";
import {
  Box,
  BoxEdit,
  BoxPrice,
  BoxPriceImg,
  BoxPriceInfo,
  BoxPriceInfoPrice,
  BoxPriceInfoTitle,
  CardCarDescription,
  CardCarHeader,
  CardCarSubTitle,
  CardCarTitle,
  EditSpeficication,
  FooterDate,
  FooterPrice,
  FooterPriceImg,
  FooterPriceInfo,
  FooterPriceInfoPrice,
  FooterPriceInfoTitle,
  SearchDate,
  Wrapper,
} from "./box-vehicle-detail.molecule.style";

interface BoxVehicleDetailProps {
  vehicleSearched: VehicleSearch;
  stateID: number;
  vehiclePriceTypeID?: number;
  editble: boolean;
  onExclusion?: Function;
}

export const BoxVehicleDetail = (props: BoxVehicleDetailProps): JSX.Element => {
  const { t } = useTranslation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [date, setDate] = useState(new Date(props.vehicleSearched?.datePrice));
  const [price, setPrice] = useState(
    props.vehicleSearched?.price?.fpp ? props.vehicleSearched.price.fpp : 0
  );

  const { GetVehiclePrices } = useKbb();
  const { DeleteVehicleSearch } = useVehicleSearch();
  const {
    setVehiclePriceTypeIdContext,
    setEquipmentsColor,
    setEquipmentsPrice,
    setEquipmentsIds,
  } = usePriceContext();

  const titleVehicle = useMemo(() => {
    return `${props.vehicleSearched?.vehicle?.modelName} ${props.vehicleSearched?.vehicle?.year} ${props.vehicleSearched?.vehicle?.versionName}`;
  }, [props.vehicleSearched]);

  const handleClickRemove = () => {
    DeleteVehicleSearch(props.vehicleSearched?.id).then(() => {
      if (props.onExclusion != null)
        props.onExclusion(props.vehicleSearched?.id);
    });
  };

  useEffect(() => {
    setDate(new Date(props.vehicleSearched.datePrice));
  }, [props.vehicleSearched.datePrice]);

  useEffect(() => {
    setPrice(
      props.vehicleSearched?.price?.fpp ? props.vehicleSearched.price.fpp : 0
    );
  }, [props.vehicleSearched, props.vehicleSearched.price.fpp]);

  const getPriceAsync = useCallback(
    async (vehicle: Vehicle) => {
      if (!vehicle?.id) return;

      const request = {
        vehicleID: vehicle.id,
        vehiclePriceTypeID: props.vehiclePriceTypeID ?? 1,
        mileage: vehicle.avMileage,
        year: vehicle.year,
        locationStateID: props.stateID,
        vehicleGradeID: 3,
        equipmentCost: props.vehicleSearched.equipmentCost ?? null,
        colorID: props.vehicleSearched.colorId ?? null,
      } as PriceAdvisorRequest;

      const priceApi = await GetVehiclePrices(request);
      setPrice(priceApi.fpp);

      let vehicleSearch = props.vehicleSearched;
      vehicleSearch.datePrice = DateHelper.toDateHourFixed(new Date());
      vehicleSearch.price = priceApi;

      setFirstLoad(false);
    },
    [GetVehiclePrices, props, setFirstLoad]
  );

  useEffect(() => {
    const dateToday = DateHelper.toDateHourFixed(new Date());
    if (
      !props.vehicleSearched.datePrice ||
      props.vehicleSearched.datePrice > dateToday
    )
      getPriceAsync(props.vehicleSearched.vehicle);
    else setFirstLoad(false);
  }, [props.vehicleSearched.vehicle]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!firstLoad) getPriceAsync(props.vehicleSearched.vehicle);
  }, [props.stateID]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!firstLoad && props.vehiclePriceTypeID !== undefined)
      getPriceAsync(props.vehicleSearched.vehicle);
  }, [props.vehiclePriceTypeID]); // eslint-disable-line react-hooks/exhaustive-deps

  const linkToEditSearch = useMemo(
    () =>
      paths
        .priceSearch()
        .replace(":vehicleId", props.vehicleSearched?.vehicle?.id.toString())
        .replace(":vehicleSearchId", props.vehicleSearched?.id.toString()),
    [props.vehicleSearched?.id, props.vehicleSearched?.vehicle?.id]
  );

  const onClickEditSearch = useCallback(() => {
    setVehiclePriceTypeIdContext(props.vehiclePriceTypeID ?? 1);
    if (props.vehicleSearched.colorId)
      setEquipmentsColor(props.vehicleSearched.colorId);
    else setEquipmentsColor(undefined);
    if (props.vehicleSearched.equipmentCost)
      setEquipmentsPrice(props.vehicleSearched.equipmentCost);
    else setEquipmentsPrice(0);
    if (props.vehicleSearched.equipmentsIds)
      setEquipmentsIds(props.vehicleSearched.equipmentsIds);
    else setEquipmentsIds([]);
  }, [
    props.vehiclePriceTypeID,
    props.vehicleSearched.colorId,
    props.vehicleSearched.equipmentCost,
    props.vehicleSearched.equipmentsIds,
    setEquipmentsColor,
    setEquipmentsIds,
    setEquipmentsPrice,
    setVehiclePriceTypeIdContext,
  ]);

  return (
    <Wrapper>
      {!!props.vehicleSearched?.id &&
        (props.editble ? (
          <>
            <BoxEdit>
              <CardCarHeader onClick={handleClickRemove}>
                <CoxIcon name="trash" />
              </CardCarHeader>
              <CardCarTitle>
                {props.vehicleSearched?.vehicle?.brandName}
              </CardCarTitle>
              <CardCarSubTitle title={titleVehicle}>
                {titleVehicle}
              </CardCarSubTitle>
              <CardCarDescription>
                <Img
                  src={
                    props.vehicleSearched?.vehicle?.firstMediaCompleteURLMedium
                  }
                />
              </CardCarDescription>
            </BoxEdit>
            <Link to={linkToEditSearch} onClick={onClickEditSearch}>
              <EditSpeficication>{t("Edit Specification")}</EditSpeficication>
            </Link>

            <FooterPrice key={`FooterPrice`}>
              <FooterPriceImg
                className={"d-none d-md-block"}
                key={`FooterPriceImg`}
              >
                <Img key={`Img`} src={KbbLogoS2Img} />
              </FooterPriceImg>
              <FooterPriceInfo key={`FooterPriceInfo`}>
                <FooterPriceInfoTitle key={`FooterPriceInfoTitle`}>
                  {t("KBBPrice")}
                </FooterPriceInfoTitle>
                <FooterPriceInfoPrice key={`FooterPriceInfoPrice`}>
                  {t("$")} {NumberHelper.toFormatString(price, 0, "--")}
                </FooterPriceInfoPrice>
              </FooterPriceInfo>
            </FooterPrice>
            <FooterDate>
              {t("Refreshed")}: {date ? format(date, "dd/MM/yyyy") : ""}
            </FooterDate>
          </>
        ) : (
          <>
            <Link to={linkToEditSearch} onClick={onClickEditSearch}>
              <Box>
                <CardCarTitle>
                  {props.vehicleSearched?.vehicle?.brandName}
                </CardCarTitle>
                <CardCarSubTitle title={titleVehicle}>
                  {titleVehicle}
                </CardCarSubTitle>
                <CardCarDescription>
                  <Img
                    src={
                      props.vehicleSearched?.vehicle
                        ?.firstMediaCompleteURLMedium
                    }
                  />
                </CardCarDescription>
              </Box>
            </Link>

            <BoxPrice key={`BoxPrice`}>
              <BoxPriceImg className={"d-none d-md-block"} key={`BoxPriceImg`}>
                <Img key={`Img`} src={KbbLogoS1Img} />
              </BoxPriceImg>
              <BoxPriceInfo key={`BoxPriceInfo`}>
                <BoxPriceInfoTitle key={`BoxPriceInfoTitle`}>
                  {props.vehiclePriceTypeID === 0
                    ? t("KBBPrice0km")
                    : props.vehiclePriceTypeID === 1
                    ? t("KBBPriceDealer")
                    : props.vehiclePriceTypeID === 2
                    ? t("KBBPricePrivate")
                    : props.vehiclePriceTypeID === 3
                    ? t("KBBPriceTradeIn")
                    : t("KBBPrice")}
                </BoxPriceInfoTitle>
                <BoxPriceInfoPrice key={`BoxPriceInfoPrice`}>
                  {t("$")} {NumberHelper.toFormatString(price, 2, "--")}
                </BoxPriceInfoPrice>
              </BoxPriceInfo>
            </BoxPrice>
            <SearchDate>
              {t("Refreshed")}: {date ? format(date, "dd/MM/yyyy") : ""}
            </SearchDate>
          </>
        ))}
    </Wrapper>
  );
};
