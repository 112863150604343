import styled, { css } from "styled-components";

interface IProps {
  active?: boolean;
}

const Wrapper = styled.div`
  /*max-width: 120px;*/
  margin: auto;
  max-width: 90px;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

const IconBox = styled.div`
  height: 42px;
  width: 72px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActiveLine = styled.div`
  width: 67px;
  height: 2px;
  background: ${({ theme }) => theme.colors.ButtonPrimary.Main};
  border-radius: 2px;
  display: inline-flex;
`;

const LabelIdentify = styled.div<IProps>`
  height: 14px;
  text-align: center;
  margin: 0;
  ${({ active }) =>
    !active
      ? css`
          color: ${(props) => props.theme.colors.ButtonPrimary.Disabled};
          opacity: 0.4;
        `
      : css`
          color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
          font-weight: bold;
        `}
`;

export { ActiveLine, IconBox, LabelIdentify, Wrapper };
