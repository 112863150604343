import styled from 'styled-components';

const SubTitle = styled.div`
  width: 218px;
  height: 28px;

  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-style: normal;
  margin: 60px 0 16px 0;
  color: ${props => props.theme.colors.Dark};
`;

const Wrapper = styled.div`
  padding-bottom: 32px;
`;

export { Wrapper, SubTitle }
