import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Brand = styled.div`
  font-weight: normal;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.grey80};
`;

const Model = styled.div`
  color: ${(props) => props.theme.colors.black};
  text-align: left !important;
  height: 42px;
  overflow: hidden;
`;

const DivImg = styled.div`
  height: 147px;
  vertical-align: middle;
`;

const YellowSeparator = styled.div`
  width: 13px;
  height: 2px;
  background: ${(props) => props.theme.colors.ButtonPrimary.Main};
  margin: 8px 0 4px 0;
`;

const KbbPrice = styled.div`
  color: ${(props) => props.theme.colors.grey80};
  background-color: ${(props) => props.theme.colors.GhostWhite};
  font-size: 10px;
  line-height: 30px;
  height: 28px;
  vertical-align: middle;
  text-align: center;
  margin: 0 -14px 0 -14px;
`;

const KbbPriceDealer = styled.div`
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.Dark};
`;

const PriceDealer = styled.div`
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
`;

const RowStyled = styled(Row)`
  margin: 0 -16px 0 -16px;
  padding: 10px 0 0 16px;
  height: 56px;
`;

const ColStyled = styled(Col)`
  padding: 0;
  margin: 0;
`;

const SearchDate = styled.div`
  margin: 4px 0 0 0;
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.grey80};
  text-align: center;
`;

const FooterDate = styled(SearchDate)`
  margin: 4px 0 32px 0;
`;

const PriceSeparator = styled.div`
  width: 1px;
  height: 32px;
  background: #dee2e5;
  border-radius: 4px;
  margin: 4px 0 0 6px;
`;

const Wrapper = styled.div`
  width: 198px;
  margin: 0 auto 16px auto;

  a {
    text-decoration: none;
  }
`;

const CardCarTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  margin: 0 0 6px 0;
`;

const CardCarSubTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 16px;
  margin: 0 0 6px 0;
  overflow: hidden;
  min-height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CardCarDescription = styled.div`
  height: 103px;
  line-height: 102px;
  width: 194px;
  text-align: center;
  vertical-align: middle;

  margin: 0 -15px 0px -15px;
  background-color: ${(props) => props.theme.colors.white};

  img {
    max-height: 102px;
    max-width: 194px;
  }
`;

const BoxPrice = styled.div`
  display: inline-flex;
  height: 47px;
  border: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  border-radius: 0px 0px 4px 4px;
  width: 100%;
`;

const BoxPriceImg = styled.div`
  margin: 7px 0 0 5px;
  img {
    width: 24px;
  }
`;

const BoxPriceInfo = styled.div`
  margin: 7px 0 0 2px;
`;

const BoxPriceInfoTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 14px;
`;

const BoxPriceInfoPrice = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
`;

const BoxEdit = styled.div`
  height: 187px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  border-bottom: none;
  position: relative;
`;

const CardCarHeader = styled.div`
  margin: -13px 5px 2px 0;
  cursor: pointer;
  position: absolute;
  right: 0;
`;

const EditSpeficication = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.colors.BlueNavy};
  border-radius: 4px 4px 0px 0px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.Background};
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: bold;
  text-align: center;
`;

const FooterPrice = styled(BoxPrice)`
  height: 55px;
  margin: 0 0 6px 0;
`;

const FooterPriceImg = styled.div`
  margin: 10px 0 0 0;
`;
const FooterPriceInfo = styled.div`
  margin: 4px 0 0 0;
`;

const FooterPriceInfoTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  line-height: 22px;
`;
const FooterPriceInfoPrice = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
`;

const Box = styled.div`
  height: 187px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  border-bottom: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.ButtonPrimary.Secondary};
  }
  &:hover img {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.ButtonPrimary.Secondary};
  }
  &:hover > ${CardCarTitle} {
    color: ${(props) => props.theme.colors.white};
  }
  &:hover > ${CardCarSubTitle} {
    color: ${(props) => props.theme.colors.white};
  }
  &:hover > ${KbbPrice} {
    color: ${(props) => props.theme.colors.white};
    background-color: rgba(250, 250, 251, 0.1);
  }
  &:hover > ${RowStyled} {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export {
  Wrapper,
  Box,
  DivImg,
  Brand,
  Model,
  YellowSeparator,
  KbbPrice,
  KbbPriceDealer,
  PriceDealer,
  RowStyled as Row,
  ColStyled as Col,
  SearchDate,
  PriceSeparator,
  CardCarTitle,
  CardCarSubTitle,
  CardCarDescription,
  BoxPrice,
  BoxPriceImg,
  BoxPriceInfo,
  BoxPriceInfoTitle,
  BoxPriceInfoPrice,
  BoxEdit,
  CardCarHeader,
  EditSpeficication,
  FooterPrice,
  FooterPriceImg,
  FooterPriceInfo,
  FooterPriceInfoTitle,
  FooterPriceInfoPrice,
  FooterDate,
};
