import {
  ButtonSecondary,
  CoxIcon,
  InputDefault,
  LabelDefault,
  Tooltip,
} from "c4u-web-components";
import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useKbb, usePriceContext, useSearchContext } from "../../../../hooks";
import {
  CarSubTitle,
  CarTitle,
  //RowCarRanking,
  //RowInternalRanking,
  ColCarCategory,
  ColCarMotor,
  ColCenter,
  ColImgVehicle,
  ColVehicleName,
  DivColCarFeatureSubTitle,
  DivColCarFeatureTitle,
  DivIcon,
  Img,
  InternalDescriptionLabel,
  InternalTitleLabel,
  // ColLevelRight,
  // ColLevelLeft,
  // ColCarRanking,
  LinkIcon,
  RowCarCategory,
  RowCarFeatures,
  RowCarRankingReduce,
  RowVehicle,
  Sharebox,
  ShareboxWrapper,
  ShareCloseButton,
  ShareSubTitle,
  ShareTitle,
  Wrapper,
} from "./vehicle-features.molecule.style";

interface VehicleFeaturesProps {
  reduced: boolean;
}

export const VehicleFeatures = (props: VehicleFeaturesProps): JSX.Element => {
  const { t } = useTranslation();

  const { vehicleContext, setVehiclesCompareContext } = useSearchContext();
  const {
    kms,
    setKmsVC,
    equipmentsColor,
    setEquipmentsColorVC,
    equipmentsPrice,
    setEquipmentsPriceVC,
    equipmentsIds,
    setEquipmentsIdsVC,
    requestPrice,
  } = usePriceContext();
  const { FormatGetInfoVehicle } = useKbb();

  const [shared, setShare] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [nameField, setNameField] = useState<string>();
  const [emailField, setEmailField] = useState<string>();
  const [emailValid, setEmailValid] = useState(false);

  const handleCompareClick = () => {
    setVehiclesCompareContext([vehicleContext]);
    setKmsVC([kms]);
    if (equipmentsColor) setEquipmentsColorVC([equipmentsColor]);
    setEquipmentsPriceVC([equipmentsPrice]);
    setEquipmentsIdsVC([equipmentsIds]);
  };

  const closeShareClick = () => {
    setShare(false);
    setSuccessMsg(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmailField(email);
    const valid = email.match(
      /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[a-zA-Z]+(\.[a-zA-Z]+)?$/i
    );
    setEmailValid(!!valid);
  };

  const printDetails = () => {
    const url = FormatGetInfoVehicle(requestPrice, equipmentsIds);
    window.open(process.env.REACT_APP_API_URL + "/Kbb/" + url);
  };

  const iconsFillColor = "#005BA880"; // Blue Manheim 50%

  return (
    <>
      {vehicleContext && (
        <Wrapper>
          {shared && (
            <Sharebox>
              <ShareCloseButton onClick={closeShareClick}>X</ShareCloseButton>

              <ShareboxWrapper>
                {successMsg ? (
                  <>
                    <Row>
                      <Col className={"text-center row-form"}>
                        <CoxIcon name="checked" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={"text-center"}>
                        <ShareTitle>{t("TitleShareVehicleSuccess")}</ShareTitle>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={"text-center row-form"}>
                        <ShareSubTitle>
                          {t("SubTitleShareVehicleSuccess")}
                        </ShareSubTitle>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={"row-form text-center"}>
                        <ButtonSecondary
                          sizex={"sm"}
                          sizey={"thin"}
                          onClick={closeShareClick}
                        >
                          OK
                        </ButtonSecondary>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col className={"text-center"}>
                        <CoxIcon name="share-color" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={"text-center"}>
                        <ShareTitle>{t("TitleShareVehicle")}</ShareTitle>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={"text-center"}>
                        <ShareSubTitle>
                          {t("SubTitleShareVehicle")}
                        </ShareSubTitle>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={"row-form"}>
                        <LabelDefault>{t("Name")}</LabelDefault>
                        <InputDefault
                          placeholder={"Digite aqui"}
                          onChange={(e) => setNameField(e.target.value)}
                          value={nameField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={"row-form"}>
                        <LabelDefault>{t("Email")}</LabelDefault>
                        <InputDefault
                          type={"Email"}
                          placeholder={"Digite aqui"}
                          onChange={handleEmailChange}
                          value={emailField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={"row-form text-right"}>
                        <ButtonSecondary
                          sizex={"sm"}
                          sizey={"thin"}
                          disabled={!nameField || !emailValid}
                          onClick={() => setSuccessMsg(true)}
                        >
                          {t("Send")}
                        </ButtonSecondary>
                      </Col>
                    </Row>
                  </>
                )}
              </ShareboxWrapper>
            </Sharebox>
          )}

          <RowVehicle>
            <ColImgVehicle xs={4}>
              <Img src={vehicleContext.firstMediaCompleteURLMedium} />
            </ColImgVehicle>
            <ColVehicleName xs={8}>
              <CarTitle>
                {vehicleContext.brandName} {vehicleContext.modelName}
              </CarTitle>
              <CarSubTitle>
                {vehicleContext.versionName} {vehicleContext.year}
              </CarSubTitle>
            </ColVehicleName>
          </RowVehicle>
          <RowCarCategory>
            <ColCenter>
              <CoxIcon name="car" fillColor={iconsFillColor} />
            </ColCenter>
            <ColCarCategory>
              <DivColCarFeatureTitle>{t("Category")}</DivColCarFeatureTitle>
              <DivColCarFeatureSubTitle>
                {vehicleContext.bodyTypeName}
              </DivColCarFeatureSubTitle>
            </ColCarCategory>
            {!props.reduced && (
              <ColCarCategory>
                <DivColCarFeatureTitle>{t("Lugares")}</DivColCarFeatureTitle>
                <DivColCarFeatureSubTitle>
                  {vehicleContext.nrSeats}
                </DivColCarFeatureSubTitle>
              </ColCarCategory>
            )}
            <ColCarCategory>
              <DivColCarFeatureTitle>{t("Portas")}</DivColCarFeatureTitle>
              <DivColCarFeatureSubTitle>
                {vehicleContext.nrDoors}
              </DivColCarFeatureSubTitle>
            </ColCarCategory>
          </RowCarCategory>
          {!props.reduced && (
            <RowCarCategory>
              <ColCenter xs={3}>
                <CoxIcon name="info" fillColor={iconsFillColor} />
              </ColCenter>
              <ColCarMotor xs={9}>
                <div>
                  <InternalTitleLabel>{t("Engine")}: </InternalTitleLabel>
                  <InternalDescriptionLabel>
                    {vehicleContext.cc} CC
                  </InternalDescriptionLabel>
                </div>
                <div>
                  <InternalTitleLabel>
                    {t("Emissions CO2")}:{" "}
                  </InternalTitleLabel>
                  <InternalDescriptionLabel>
                    {vehicleContext.emissionsCO2} {t("g_km")}
                  </InternalDescriptionLabel>
                </div>
              </ColCarMotor>
            </RowCarCategory>
          )}
          <RowCarFeatures>
            <ColCenter xs={4}>
              <DivIcon>
                <CoxIcon name="transmission" fillColor={iconsFillColor} />
              </DivIcon>
              <DivColCarFeatureTitle>{t("Transmission")}</DivColCarFeatureTitle>
              <DivColCarFeatureSubTitle>
                {vehicleContext.transmissionTypeName}
              </DivColCarFeatureSubTitle>
            </ColCenter>
            <ColCarCategory xs={4}>
              <DivIcon>
                <CoxIcon name="piston" fillColor={iconsFillColor} />
              </DivIcon>
              <DivColCarFeatureTitle>{t("Power")}</DivColCarFeatureTitle>
              <DivColCarFeatureSubTitle>
                {vehicleContext.hp} CV
              </DivColCarFeatureSubTitle>
            </ColCarCategory>
            <ColCarCategory xs={4}>
              <DivIcon>
                <CoxIcon name="speed" fillColor={iconsFillColor} />
              </DivIcon>
              <DivColCarFeatureTitle>{t("Acceleration")}</DivColCarFeatureTitle>
              <DivColCarFeatureSubTitle>
                {`${vehicleContext.acceleration0to100} s (${
                  props.reduced ? "0-100" : "de 0 a 100"
                } ${t("km_h")})`}
              </DivColCarFeatureSubTitle>
            </ColCarCategory>
          </RowCarFeatures>
          <RowCarFeatures>
            <ColCenter xs={4}>
              <DivIcon>
                <CoxIcon name="race-car" fillColor={iconsFillColor} />
              </DivIcon>
              <DivColCarFeatureTitle>{t("Traction")}</DivColCarFeatureTitle>
              <DivColCarFeatureSubTitle>
                {vehicleContext.driveTrainName}
              </DivColCarFeatureSubTitle>
            </ColCenter>
            <ColCarCategory xs={4}>
              <DivIcon>
                <CoxIcon name="gas-station" fillColor={iconsFillColor} />
              </DivIcon>
              <DivColCarFeatureTitle>{t("Fuel")}</DivColCarFeatureTitle>
              <DivColCarFeatureSubTitle>
                {vehicleContext.fuelTypeName}
              </DivColCarFeatureSubTitle>
            </ColCarCategory>
            <ColCarCategory xs={4}>
              <DivIcon>
                <CoxIcon name="engine-oil" fillColor={iconsFillColor} />
              </DivIcon>
              <DivColCarFeatureTitle>{t("Consumption")}</DivColCarFeatureTitle>
              <DivColCarFeatureSubTitle>
                {vehicleContext.urbanConsumption} {t("km_l")} ({t("city")})
              </DivColCarFeatureSubTitle>
            </ColCarCategory>
          </RowCarFeatures>

          {!props.reduced ? (
            <>
              {/* <RowCarRanking>
                <ColCenter xs={4}></ColCenter>
                <ColCarRanking xs={4}>
                  <RowInternalRanking>
                    <ColLevelLeft xs={3}>
                      <CoxIcon name="engine-oil" fillColor={iconsFillColor} />
                    </ColLevelLeft>
                    <ColLevelRight xs={9}>
                      <DivColCarFeatureTitle>
                        {t("Consumption")}
                      </DivColCarFeatureTitle>
                      <DivColCarFeatureSubTitle>
                        Nº 2 a 8
                      </DivColCarFeatureSubTitle>
                    </ColLevelRight>
                  </RowInternalRanking>
                </ColCarRanking>
                <ColCarRanking xs={4}>
                  <RowInternalRanking>
                    <ColLevelLeft xs={3}>
                      <CoxIcon name="piston" fillColor={iconsFillColor} />
                    </ColLevelLeft>
                    <ColLevelRight xs={9}>
                      <DivColCarFeatureTitle>
                        {t("Power")}
                      </DivColCarFeatureTitle>
                      <DivColCarFeatureSubTitle>
                        Nº 3 a 10
                      </DivColCarFeatureSubTitle>
                    </ColLevelRight>
                  </RowInternalRanking>
                </ColCarRanking>
              </RowCarRanking> */}
            </>
          ) : (
            <RowCarRankingReduce>
              <Col xs={"8"} className={"text-left pr-0"}></Col>
              <Col xs={"4"} className={"text-right pl-0"}>
                {/* <LinkIcon onClick={() => setShare(true)}>
                  <Tooltip content={t("Share")}>
                    <CoxIcon name="share" />
                  </Tooltip>
                </LinkIcon> */}
                <LinkIcon onClick={printDetails}>
                  <Tooltip content={t("Print")}>
                    <CoxIcon name="print" />
                  </Tooltip>
                </LinkIcon>
                <LinkIcon onClick={handleCompareClick}>
                  <Tooltip content={t("Compare")}>
                    <CoxIcon name="compare" />
                  </Tooltip>
                </LinkIcon>
              </Col>
            </RowCarRankingReduce>
          )}
        </Wrapper>
      )}
    </>
  );
};
