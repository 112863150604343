import React, { useEffect } from "react";
import { H1 } from "c4u-web-components";
import { SearchVehicle, LatestSearches } from "../../organisms";
import { useTranslation } from "react-i18next";

import { Wrapper, SubTitle } from "./home.page.style";
import { useSessionContext, useGeolocation } from "../../../hooks";

export const HomePage = () => {
  const { t } = useTranslation();

  const { setGlobalSate } = useSessionContext();

  const { GetGeolocation } = useGeolocation();

  const setStateGeolocation = async (latitude: number, longitude: number) => {
    const geolocation = await GetGeolocation(latitude, longitude);
    setGlobalSate(geolocation.address?.state);
  };

  const getBrowserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        setStateGeolocation(
          location.coords.latitude,
          location.coords.longitude
        );
      },
      () => {
        setGlobalSate("São Paulo");
      }
    );
  };

  useEffect(() => {
    getBrowserLocation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <H1>{t("HomeTitle")}</H1>
      <SearchVehicle />
      <SubTitle>{t("LastestSearches")}</SubTitle>
      <LatestSearches />
    </Wrapper>
  );
};
