import { AxiosInstance } from "axios";

import { useAuth0 } from "@auth0/auth0-react";
import { useSessionContext } from "../../../contexts/use-session-context.hook";

export const useRequest = (http: AxiosInstance, path: string) => {
  const { anonymousContext, token } = useSessionContext();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const withAuthorization = async (headers: any) => {
    if (!token && !isAuthenticated) return headers;

    headers["Authorization"] = `Bearer ${
      token || (await getAccessTokenSilently())
    }`;

    return headers;
  };

  const buildUrl = (url: string) => {
    const localPath =
      anonymousContext?.isAnonymous && path === "KbbMolicar" ? "Kbb" : path;

    return localPath + (url ? `/${url}` : "");
  };

  const buildHeaders = async () => {
    const headers = {
      "Accept-Language": "pt-BR,pt;q=0.9",
    };

    return await withAuthorization(headers);
  };

  const handleErrors = (e: any): void => {
    // TODO: tratar erro generico
    if (e.response) {
      console.log("Request", e.response);
      throw new Error(e.response);
    } else {
      //throw new Error('generico');
      console.log("General", e);
      throw new Error(e);
    }
  };

  const callApi = async (
    url: string,
    method: string,
    config: any,
    data: any = null
  ) => {
    const requestConfig = config;
    requestConfig.url = buildUrl(url);
    requestConfig.headers = await buildHeaders();
    requestConfig.data = data;
    requestConfig.method = method;
    try {
      return await http.request(requestConfig);
    } catch (e) {
      handleErrors(e);
    }
  };

  const getCurrentToken = async (): Promise<string> => {
    if (anonymousContext?.isAnonymous) return "";
    return await getAccessTokenSilently({
      audience: "https://coxpravoce.com.br",
      scope: "kbb",
    });
  };

  return {
    get: async (url: string, config = {}): Promise<any> =>
      callApi(url, "GET", config),
    deleteCall: async (url: string, config = {}): Promise<any> =>
      callApi(url, "DELETE", config),
    put: async (url: string, data: any, config = {}): Promise<any> =>
      callApi(url, "PUT", config, data),
    post: async (url: string, data: any, config = {}): Promise<any> =>
      callApi(url, "POST", config, data),
    getCurrentToken,
  };
};
